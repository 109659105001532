import { Component, Input, OnChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { FileService } from "../../../services/utilities/file.service";
import { FileSize } from "../../../enums/file-size.enum";
import { AlertService } from "../../../services/utilities/alert.service";

@Component({
    selector: "app-table-file-upload",
    templateUrl: "./table-file-upload.component.html",
    styleUrl: "./table-file-upload.component.scss",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TableFileUploadComponent),
            multi: true,
        },
    ],
})
export class TableFileUploadComponent implements ControlValueAccessor, OnChanges {
    @Input() documentTypeName!: string;
    @Input() fileData?: TableFileUploadData;
    @Input() canEdit: boolean = true;

    // ControlValueAccessor
    disabled: boolean = false;
    value: TableFileUploadData | null = null;
    onChanged: any = () => { };
    onTouched: any = () => { };

    constructor(
        private fileService: FileService,
        private alertService: AlertService,
    ) { }

    ngOnChanges(): void {
        this.value = this.fileData || null;
    }

    onDownload(): void {
        this.fileService.downloadFileBase64(
            this.value!.fileBase64,
            this.value!.fileType,
            this.value!.fileName
        )
    }

    onFileChamged(event: Event): void {
        const file: File = (event.target as HTMLInputElement).files![0];
        if (file.size > this.fileService.sizeToBytes(10, FileSize.UNIT_MB)) {
            this.alertService.error(`File size exceeded! (10MB)`);
            this.onFileRemoved();
        } else {
            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (e: any) => {
                console.log(e)
                this.value = {
                    fileBase64: this.fileService.convertDataURLToBase64(e.target.result),
                    fileName: file.name,
                    fileType: this.fileService.getMimeByExt(file.name),
                };
                this.onChanged(this.value);
                this.onTouched();
            };
        }
    }

    onFileRemoved(): void {
        this.value = null;
        this.onChanged(null);
        this.onTouched();
    }

    // ControlValueAccessor Interfaces
    writeValue(value: any): void {
        this.value = value;
    }
    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}

export interface TableFileUploadData {
    fileBase64: string;
    fileName: string;
    fileType: string;
}