<form>
    <mat-form-field appearance="outline" [ngClass]="className">
        <mat-label for="select-requester-name">Requester Name</mat-label>
        <input type="text" matInput [formControl]="form" [matAutocomplete]="auto" [readonly]="readonly">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="onSelectChange($event)">
            @for (option of filteredOptions | async; track option) {
                <mat-option [value]="(!!value ? option[value] : option)">
                    {{!!display ? option[display] : option}}
                </mat-option>
            }
        </mat-autocomplete>
        <button mat-icon-button *ngIf="formValue" matSuffix aria-label="Clear"
            [disabled]="disabled"
            (click)="formValue = null; onSelectChange(); $event.stopPropagation();">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</form>