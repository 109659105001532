@if(isLoading) {
    Loading.....
}

@if (fwdData && fwdData.fwu_fwd_groups.length > 0) {
<h2 class="text-black">FWD with/without underlying</h2>
<form [formGroup]="fwdFwuForm">
    <mat-accordion multi>
        @for (group of fwdData.fwu_fwd_groups; track group;) {
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{group.currency }} | Direction: {{group.direction_code_name}} | Total: {{group.total | number: '1.2-2'}}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="overflow-auto">
                <table class="mat-table-style" formArrayName="fwuRequests">
                    <thead>
                        <tr>
                            <th class="mat-mdc-header-cell">Status</th>
                            <th class="mat-mdc-header-cell">FWD ID</th>
                            <th class="mat-mdc-header-cell">Objective</th>
                            <th class="mat-mdc-header-cell">Bank *</th>
                            <th class="mat-mdc-header-cell">Estimate Received Date*</th>
                            <th class="mat-mdc-header-cell">Forward Type</th>
                            <th class="mat-mdc-header-cell" style="text-align: right;">Rate</th>
                            <th class="mat-mdc-header-cell" style="text-align: right;">Total Request</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of getFormGroupsByGroupNo(group.group_no); let i=index">
                            <ng-container [formGroup]="item">
                                <td class="mat-mdc-cell"> {{ getFormControlValue(group.group_no, i, 'status') }} </td>
                                <td class="mat-mdc-cell"> {{ getFormControlValue(group.group_no, i, 'fwdId') }} </td>

                                <!-- Only unbooked item can be edited -->
                                @if(getFormControlValue(group.group_no, i, 'isUnBook')) {
                                <td class="mat-mdc-cell">
                                    <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                                        <input type="text" matInput formControlName="objective" />
                                    </mat-form-field>
                                </td>
                                <td class="mat-mdc-cell py-2">
                                    <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                                        <mat-select formControlName="bank">
                                            <mat-option [value]="bank.bank_id"
                                                *ngFor="let bank of fwdData.dollar_link_dropdown_data.banks; let i = index">
                                                {{bank.bank_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td class="mat-mdc-cell">
                                    <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                                        <input matInput [matDatepicker]="estimateRecievedDate"
                                            formControlName="estimateRecievedDate">
                                        <mat-datepicker-toggle matIconSuffix
                                            [for]="estimateRecievedDate"></mat-datepicker-toggle>
                                        <mat-datepicker #estimateRecievedDate></mat-datepicker>
                                    </mat-form-field>
                                </td>
                                }

                                @else {
                                <td class="mat-mdc-cell">
                                    {{ getFormControlValue(group.group_no, i, 'objective') }}
                                </td>
                                <td class="mat-mdc-cell">
                                    {{ getBankNameFromBankId(getFormControlValue(group.group_no, i, 'bank'), fwdData.dollar_link_dropdown_data.banks) }}
                                </td>
                                <td class="mat-mdc-cell">
                                    {{ getFormControlDateValue(group.group_no, i, 'estimateRecievedDate') | date:
                                    'dd/MM/yyyy HH:mm'}}
                                </td>
                                }

                                <td class="mat-mdc-cell">
                                    {{ getFormControlValue(group.group_no, i, 'forwardTypeDesc') }}
                                </td>
                                <td class="mat-mdc-cell" style="text-align: right;">
                                    {{ getFormControlValue(group.group_no, i, 'rate') }} </td>
                                <td class="mat-mdc-cell" style="text-align: right;">
                                    {{ getFormControlNumberValue(group.group_no, i, 'totalRequest') | number: '1.2-2'}}
                                </td>
                            </ng-container>
                        </tr>
                        <!-- <tr class="mat-row" *ngIf="group.data.length === 0">
                            <td class="mat-cell" colspan="100%" style="text-align: center;">No data.</td>
                        </tr> -->
                    </tbody>
                </table>
            </div>

        </mat-expansion-panel>
        }
    </mat-accordion>
</form>
<br>
<p>Remark: In case of L/C term and will do discount without recourse, please specify your discounting bank.</p>
}

@if (fwdData && fwdData.show_dollar_link) {
<mat-divider></mat-divider>
<div class="mt-5"></div>
<h2 class="text-black">Dollar Link</h2>

<div class="overflow-auto">
    <form [formGroup]="dollarLinkForm">
        <mat-checkbox formControlName="requestDollarLink" (change)="onCheckDollarLink($event)">Request Dollar Link</mat-checkbox>
        <table class="mat-table-style" >
            <thead>
                <tr>
                    <th class="mat-mdc-header-cell">Status</th>
                    <th class="mat-mdc-header-cell whitespace-nowrap">FWD ID</th>
                    <th class="mat-mdc-header-cell">Objective *</th>
                    <th class="mat-mdc-header-cell">Bank *</th>
                    <th class="mat-mdc-header-cell">Estimate Payment Date *</th>
                    <th class="mat-mdc-header-cell">Forward Type</th>
                    <th class="mat-mdc-header-cell">Currency</th>
                    <th class="mat-mdc-header-cell">Rate</th>
                    <th class="mat-mdc-header-cell" style="text-align: right;">Request Amount</th>
                </tr>
            </thead>
            <tbody>
                <tr [hidden]="!isRequestDollarLink">
                    <td class="mat-mdc-cell">
                        {{getDollarLinkFormControlValue('status')}}
                    </td>
                    <td class="mat-mdc-cell">
                        {{getDollarLinkFormControlValue('fwdId')}}
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                            <input type="text" matInput formControlName="objective" />
                        </mat-form-field>
                    </td>
                    <td class="mat-mdc-cell py-2">
                        <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                            <mat-select formControlName="bank">
                                <mat-option [value]="bank.bank_id"
                                    *ngFor="let bank of fwdData.dollar_link_dropdown_data.banks; let i = index">
                                    {{bank.bank_name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                            <input matInput [matDatepicker]="estimatePaymentDate"
                                formControlName="estimatePaymentDate">
                            <mat-datepicker-toggle matIconSuffix [for]="estimatePaymentDate"></mat-datepicker-toggle>
                            <mat-datepicker #estimatePaymentDate></mat-datepicker>
                        </mat-form-field>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                            <input type="text" matInput formControlName="forwardTypeDesc" />
                        </mat-form-field>
                    </td>
                    <td class="mat-mdc-cell">
                        <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                            <input type="text" matInput formControlName="currency" />
                        </mat-form-field>
                    </td>
                    <td class="mat-mdc-cell" style="text-align: right;">
                        <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                            <input type="number" matInput formControlName="rate" />
                        </mat-form-field>
                    </td>
                    <td class="mat-mdc-cell" style="text-align: right;">
                        <mat-form-field appearance="outline" class="py-2" subscriptSizing="dynamic">
                            <!-- <input type="number" matInput formControlName="requestAmount" /> -->
                            <input type="text" thousands-separator [minFractionDigits]="4" [maxFractionDigits]="4" matInput formControlName="requestAmount" />
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
}

@if(showNoBookingMessage) {
    <p>No booking data available. You may proceed to the next step.</p>
}