<form [formGroup]="form" *ngIf="dropdown$ | async as dropdownData; else loading">
    <!-- <pre>{{form.value | json}}</pre> -->
    <div class="grid grid-cols-1 gap-2" >
        <div class="col-span-1">
            <app-mat-autocomplete formControlName="requesterEmail" 
                (selectionChange)="onSelectRequesterChange($event)"
                [formName]="'Requester'"
                [options]="dropdownData.requesters" [displayFunction]="requesterNameDisplayFunction()" [key]="'email'" [filter]="'email,name,lastname'">
            </app-mat-autocomplete>
        </div>

        <div class="col-span-1">
            <div class="grid grid-cols-2 gap-2">
                <div class="col-span-1">
                    <app-mat-autocomplete formControlName="creatorEmail" (selectionChange)="onSelectCreator($event, dropdownData.creatorCostCenters)"
                        [formName]="'Creator'" [options]="dropdownData.creator"
                        [displayFunction]="requesterNameDisplayFunction()" [key]="'email'" [filter]="'email,name,lastname'">
                    </app-mat-autocomplete>
                </div>
                <div class="col-span-1">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-label for="select-cost-center">Creator Cost Center & Division</mat-label>
                    <mat-select id="select-cost-center" formControlName="creatorCostCenter" (selectionChange)="onSelectCreatorCostCenter($event.value, dropdownData.creatorCostCenters)">
                        <mat-option [value]="costCenter.cost_center_name" *ngFor="let costCenter of dropdownData.creatorCostCenters; let i = index">
                            {{costCenter.cost_center_name}} / {{costCenter.division}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col-span-1">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label for="select-requester-name">Created By</mat-label>
                <input id="select-requester-name" type="text" matInput formControlName="createdByEmail" />
            </mat-form-field>
        </div>
        <div class="col-span-1">
            <mat-form-field appearance="outline" class="w-full">
                <mat-label for="select-requester-name_by">CreatedName By</mat-label>
                <input id="select-requester-name_by" type="text" matInput formControlName="createdByName" />
            </mat-form-field>
        </div>
    </div>

    <div class="grid grid-cols-2 gap-2 w-full">
        <mat-form-field appearance="outline">
            <mat-label for="select-cost-center">Requester Cost Center</mat-label>
            <mat-select id="select-cost-center" formControlName="costCenter" (selectionChange)="onSelectCostCenter($event.value, dropdownData)">
                <mat-option [value]="costCenter.cost_center_name" *ngFor="let costCenter of dropdownData.costCenters; let i = index">
                    {{costCenter.cost_center_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline">
            <mat-label for="select-sales-group">Sales Group</mat-label>
            <mat-select id="select-sales-group" formControlName="salesGroup" (selectionChange)="onSelectSalesGroup(dropdownData)">
                <mat-option [value]="salesGroup.sales_group_name" *ngFor="let salesGroup of dropdownData.salesGroup; let i = index">
                    {{salesGroup.sales_group_name === "" ? "(No sales group)" : salesGroup.sales_group_name}}
                    {{ salesGroup.product_team_name? " - " + salesGroup.product_team_name : ""  }}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>

    <div class="grid grid-cols-2 gap-2 w-full">
        <mat-form-field appearance="outline">
            <mat-label for="input-department">Department</mat-label>
            <input id="input-department" type="text" matInput formControlName="department"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label for="input-division">Division</mat-label>
            <input id="input-division" type="text" matInput formControlName="division"/>
        </mat-form-field>
    </div>

    <div class="grid grid-cols-3 gap-2 w-full">
        <mat-form-field appearance="outline">
            <mat-label for="input-company-code">Company Code</mat-label>
            <input id="input-company-code" type="text" matInput formControlName="companyCode"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label for="input-company">Company</mat-label>
            <input id="input-company" type="text" matInput formControlName="companyName"/>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label for="input-country">Country</mat-label>
            <input id="input-country" type="text" matInput formControlName="country" readonly />
        </mat-form-field>
    </div>
</form>

<ng-template #loading>Loading....</ng-template>