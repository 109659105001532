import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-table-file-upload-with-name',
  templateUrl: './table-file-upload-with-name.component.html',
  styleUrl: './table-file-upload-with-name.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TableFileUploadWithNameComponent),
      multi: true,
    },
  ],
})
export class TableFileUploadWithNameComponent implements ControlValueAccessor {
  @Input() documentTypeName!: string;
  documenteName : any;
  // documentTypeName: FormControl<string | null> | undefined;

  // ControlValueAccessor
  disabled: boolean = false;
  value: File | null = null;
  onChanged: any = () => { };
  onTouched: any = () => { };

  onDownload(): void {
    const fileFromInput: File = this.value!;
    const link = document.createElement('a');
    link.download = fileFromInput.name;
    link.href = URL.createObjectURL(fileFromInput);
    link.click();
  }

  onFileChamged(event: Event): void {
    const file: File = (event.target as HTMLInputElement).files![0];
    this.value = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        let objectreturn = {
          "attachment_name" : file.name,
          "base64_encoded" : reader.result,
          "mime_type" : file.type,
          "attachment_desc" : this.documenteName
        }
        this.onChanged(objectreturn);
    };
    this.onTouched();
  }

  onFileRemoved(): void {
    this.value = null;
    this.onChanged(null);
    this.onTouched();
  }

  // ControlValueAccessor Interfaces
  writeValue(value: any): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
