@if (checkDoaError) {
    <h3 class="text-red-600">INF_CHECK_DOA error. Please contact app administrator.</h3>
    <h3 class="text-red-700" *ngIf="checkDoaError?.message">Error message: {{checkDoaError.message}}</h3>

    <ng-container *ngFor="let topic of checkDoaError.data?.topics">
       <p class="m-0 p-0">doa section: {{topic.doa_section}} </p>
       <p class="m-0 p-0">error message:-</p>
        <ul>
            <li  *ngFor="let msg of topic.error_message">{{msg}}</li>
        </ul>
    </ng-container>
}

@if(checkDoaError) {
    <div class="mt-3"></div>
    <mat-divider></mat-divider>
    <div class="mt-3"></div>
}

@if(checkApproverError) {
    <h3 class="text-red-600">INF_CHECK_APPROVER error. Please contact app administrator.</h3>
    <h3 class="text-red-600" *ngIf="checkApproverError?.message">Error message: {{checkApproverError.message}}</h3>
}

<!-- @if(checkWorkflowError) {
    <h3>INF_CREATE_WF error. Please contact app administrator.</h3>
    <h3 *ngIf="checkWorkflowError?.message">Error message: {{checkWorkflowError.message}}</h3>
} -->