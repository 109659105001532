<div class="mat-elevation-z2 overflow-x-auto">
    <table class="mat-table-style">
        <thead>
            <tr>
                <th class="mat-mdc-header-cell">Step</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Auth Level</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Cost Center</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Designated Approver</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Actual Approver</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Position</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Action</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Date</th>
                <th class="mat-mdc-header-cell whitespace-nowrap">Comment</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let t of data">
                <td class="mat-mdc-cell">{{t.step_no}}</td>
                <td class="mat-mdc-cell whitespace-nowrap">{{t.doa_auth_level}}</td>
                <td class="mat-mdc-cell whitespace-nowrap">{{t.node_id}}</td>
                <td class="mat-mdc-cell whitespace-nowrap">{{t.designated_approver_name ? t.designated_approver_name : "-"}}</td>
                <td class="mat-mdc-cell whitespace-nowrap">{{t.actual_approver_name ? t.actual_approver_name : "-"}}</td>
                <td class="mat-mdc-cell whitespace-nowrap">
                    @if(t.is_group_approval) {
                        {{t.group_of_member_name_en}}
                    }
                    @else {
                    {{getMappedAuthLevel(t.doa_auth_level)}}
                    }
                </td>
                <td class="mat-mdc-cell whitespace-nowrap">{{t.task_status ? t.task_status : "-"}}</td>
                <td class="mat-mdc-cell whitespace-nowrap">{{t.update_date ? (t.update_date |  date: 'dd/MM/yyyy HH:mm') : "-"}}</td>
                <td class="mat-mdc-cell">{{t.comment ? t.comment : "-"}}</td>
            </tr>
            <tr class="mat-row" *ngIf="data.length === 0">
                <td class="mat-cell" colspan="100%" style="text-align: center;">No data.</td>
            </tr>
        </tbody>
    </table>
</div>