import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "../reducers/app.reducer";

const state = createFeatureSelector<AppState>("appState");

export const selectSideBarState = createSelector(
    state,
    (state: AppState) => state.showSideBar
);

export const selectAllAppState = createSelector(
    state,
    (state: AppState) => state
);