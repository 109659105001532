import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import moment from "moment";

@Injectable({
    providedIn: "root",
})
export class FormatService {
    constructor() {}

    formatBytes(bytes: number): string {
        if (bytes === 0) {
            return "0 Bytes";
        }
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    }

    getDateTimeFileExportFormat(): string {
        return moment().format("YYYY-MM-DD_HH-mm");
    }

    // numberToThousandsSeparator(input: any, format: string): string | null {
    //     if (input && Number(input)) {
    //         return this.decimalPipe.transform(Number(input), format);
    //     }
    //     else {
    //         return null;
    //     }
    // }

    thousandsSeparatorToNumber(input: string): number | null {
        if (input) {
            return Number(String(input).replaceAll(',', ''));
        }
        else {
            return null
        }
    }
}
