import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, combineLatest, map, of } from "rxjs";
import {
    RequesterDropdownResponse,
    CostCenterDropdownResponse,
    SalesGroupDropdownResponse,
} from "../../../api/common/models/dropdown.model";
import { CreateFWORequestService } from "../../../api/fwd/fwd-request/create-fwo-request/services/create-fwo-request.service";
import { COMP_CODE } from "../../../constant/conpany-code.constant";

export interface RequesterInformationDropdown {
    requesters: RequesterDropdownResponse[];
    creator: RequesterDropdownResponse[];
    costCenters: CostCenterDropdownResponse[];
    salesGroup: SalesGroupDropdownResponse[];
    department: string | null;
    division: string | null;
    companyCode: string | null;
    company: string | null;
    country: string | null;
    creatorCostCenters: CostCenterDropdownResponse[];
}

@Injectable()
export class RequesterInformationFormService {
    constructor(private createFWORequestService: CreateFWORequestService) {}

    private requesterSubject = new BehaviorSubject<string | null>(null);
    requesterAction$ = this.requesterSubject.asObservable();
    onRequesterChange(requesterEmail: string | null) {
        this.requesterSubject.next(requesterEmail);
    }

    private creatorSubject = new BehaviorSubject<string | null>(null);
    creatorAction$ = this.creatorSubject.asObservable();
    onCreatorChange(creatorEmail: string | null) {
        this.creatorSubject.next(creatorEmail);
    }

    private costCenterSubject = new BehaviorSubject<string | null>(null);
    costCenterAction$ = this.costCenterSubject.asObservable();
    onCostCenterChange(costCenter: string | null) {
        this.costCenterSubject.next(costCenter);
    }

    dropdown$ = combineLatest({
        requesters: this.createFWORequestService.requesters$,
        selectedRequesterEmail: this.requesterAction$,
        selectedCostCenterName: this.costCenterAction$,
        selectedCreatorEmail: this.creatorAction$
    }).pipe(
        map(({ requesters, selectedRequesterEmail, selectedCostCenterName, selectedCreatorEmail }) => {
            const availableCostCenters: CostCenterDropdownResponse[] = this.getAvailableCostCenters(
                requesters.requestors,
                selectedRequesterEmail,
            );
            const availableSalesGroups: SalesGroupDropdownResponse[] = this.getAvailableSalesGroups(
                availableCostCenters,
                selectedCostCenterName,
            );

            const selectedCostCenter = availableCostCenters.find((c) => c.cost_center_name === selectedCostCenterName);

            const result: RequesterInformationDropdown = {
                requesters: requesters.requestors,
                creator: requesters.creators,
                costCenters: availableCostCenters,
                salesGroup: availableSalesGroups,
                department: selectedCostCenter?.department ?? null,
                division: selectedCostCenter?.division ?? null,
                companyCode: selectedCostCenter?.comp_code ?? null,
                company: selectedCostCenter?.comp_name_en ?? null,
                country: selectedCostCenter?.country ?? null,
                creatorCostCenters: this.getAvailableCostCenters(requesters.creators, selectedCreatorEmail)
            };

            // why are you here again?
            this.createFWORequestService.onComCodeSelected(selectedCostCenter?.comp_code ?? '');

            return result;
        }),
        catchError((err) => {
            console.log(err);
            const defaultValue: RequesterInformationDropdown = {
                requesters: [],
                creator: [],
                costCenters: [],
                salesGroup: [],
                department:  null,
                division: null,
                companyCode:  null,
                company:  null,
                country: null,
                creatorCostCenters: []
            };
            return of(defaultValue);
        })
    );

    private getAvailableCostCenters(requesters: RequesterDropdownResponse[], selectedRequesterEmail: string | null): CostCenterDropdownResponse[] {
        return requesters
            .filter((r) => (selectedRequesterEmail === null ? true : r.email === selectedRequesterEmail))
            .flatMap((r) => r.cost_centers);
    }

    private getAvailableSalesGroups(availableCostCenters: CostCenterDropdownResponse[], selectedCostCenterName: string | null): SalesGroupDropdownResponse[] {
        return availableCostCenters
            .filter((r) => (selectedCostCenterName === null ? true : r.cost_center_name === selectedCostCenterName))
            .flatMap((r) => r.sales_groups);
    }
}
