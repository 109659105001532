<div class="mat-elevation-z2">
    <table mat-table [dataSource]="[{}]">
        <!-- View Column -->
        <ng-container matColumnDef="view">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;">View</th>
            <td mat-cell *matCellDef="let row" style="text-align: center;">
                <button mat-flat-button color="primary" class="my-2" (click)="onDownload()"
                    [disabled]="!value">
                    <mat-icon>get_app</mat-icon>Download
                </button>
            </td>
        </ng-container>

        <!-- Document Column -->
        <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef style="width: 30%;">Document</th>
            <!-- <td mat-cell *matCellDef="let row">{{documentTypeName}}</td> -->
            <!-- <input *matCellDef="let row" matInput formControlName="documentTypeName"> -->
            <td mat-cell *matCellDef="let row" style="text-align: center;">
                <mat-form-field appearance="outline" class="w-full my-2" subscriptSizing="dynamic">
                    <input matInput [(ngModel)]="documenteName">
                </mat-form-field>
            </td>
        </ng-container>
        <!-- File Name Column -->
        <ng-container matColumnDef="fileName">
            <th mat-header-cell *matHeaderCellDef style="width: 40%;">File Name</th>
            <td mat-cell *matCellDef="let row">{{ value?.name || '-' }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width: 20%; text-align: center;">Action</th>
            <td mat-cell *matCellDef="let row">
                <div class="flex gap-x-3 justify-center items-center">
                    <label for="file">
                        <mat-icon class="cursor-pointer">attach_file</mat-icon>
                        <input type="file" id="file" (change)="onFileChamged($event)" hidden>
                    </label>
                    <mat-icon class="cursor-pointer" (click)="onFileRemoved()">delete</mat-icon>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['view', 'document', 'fileName', 'action']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['view', 'document', 'fileName', 'action']"></tr>
    </table>
</div>