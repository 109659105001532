import { Injectable } from "@angular/core";
import { Observable, map, shareReplay, switchMap } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseService, ServiceName } from "../../../services/base-service";
import { APIUrlToUse } from "../../../enums/api-url-to-use.enum";
import { APIResponse } from "../models/api-response.model";
import {
    CommonDropdownResponse,
    CurrencyDropdownResponse,
    CurrencyHistoryDropdownResponse,
    GetRequesterDropdownResponse,
    RequesterDropdownResponse,
} from "../models/dropdown.model";
import { Store } from "@ngrx/store";
import { selectAllAppState } from "../../../store/selectors/app.selector";
import { selectFWORequestIdAndProcessId } from "../../../store/selectors/create-fwo-request.selector";
import { selectRequestIdAndProcessId } from "../../../store/selectors/create-q2o-request.selector";

@Injectable({
    providedIn: "root",
})
export class CommonDropdownService extends BaseService {
    constructor(
        private http: HttpClient,
        private store: Store
    ) {
        super(APIUrlToUse.deployed, "http://localhost:3001", ServiceName.master);
    }

    private readonly url = {
        purchasingOrg: "/common/dropdown/purchasing-org",
        currency: "/common/dropdown/currency",
        mainCurrencyHistory: "/common/dropdown/history/main-currency",
        convCurrencyHistory: "/common/dropdown/history/conv-currency",
        requester: "/common/dropdown/requester",
    };

    private get getPurchasingOrgUrl(): string {
        return this.prepareUrl(this.url.purchasingOrg);
    }

    private get getCurrencyUrl(): string {
        return this.prepareUrl(this.url.currency);
    }

    private get getMainCurrencyHistoryUrl(): string {
        return this.prepareUrl(this.url.mainCurrencyHistory);
    }

    private get getConvCurrencyHistoryUrl(): string {
        return this.prepareUrl(this.url.convCurrencyHistory);
    }

    private get getRequesterUrl(): string {
        return this.prepareUrl(this.url.requester);
    }

    getPurchasingOrg(): Observable<APIResponse<CommonDropdownResponse[]>> {
        return this.http.get<APIResponse<CommonDropdownResponse[]>>(this.getPurchasingOrgUrl).pipe(shareReplay(1));
    }

    getCurrency(): Observable<APIResponse<CurrencyDropdownResponse[]>> {
        return this.http.get<APIResponse<CurrencyDropdownResponse[]>>(this.getCurrencyUrl).pipe(shareReplay(1));
    }

    getMainCurrencyHistory(): Observable<APIResponse<CurrencyHistoryDropdownResponse[]>> {
        return this.http
            .get<APIResponse<CurrencyHistoryDropdownResponse[]>>(this.getMainCurrencyHistoryUrl)
            .pipe(shareReplay(1));
    }

    getConvCurrencyHistory(): Observable<APIResponse<CurrencyHistoryDropdownResponse[]>> {
        return this.http
            .get<APIResponse<CurrencyHistoryDropdownResponse[]>>(this.getConvCurrencyHistoryUrl)
            .pipe(shareReplay(1));
    }

    // getRequesters(): Observable<RequesterDropdownResponse[]> {
    //     return this.http.get<APIResponse<GetRequesterDropdownResponse>>(this.getRequesterUrl).pipe(
    //         map((i) => i.data.requestors),
    //         shareReplay(1),
    //     );
    // }

    getRequesters(): Observable<GetRequesterDropdownResponse> {
        const appState$ = this.store.select(selectAllAppState);
        return appState$.pipe(
            switchMap(({isQ2O, isFWO})=> {
                const selectorToUse = isQ2O ? selectRequestIdAndProcessId : selectFWORequestIdAndProcessId;
                return this.store.select(selectorToUse).pipe(
                    switchMap(({ requestId, processId }) => {
                        const params = new HttpParams()
                            .set("request_id", requestId)
                            .set("process_id", processId);
                        return this.http.get<APIResponse<GetRequesterDropdownResponse>>(this.getRequesterUrl, { params: params }).pipe(
                            map((i) => i.data),
                            shareReplay(1),
                        );
                    })
                );
            })
        )
    }
}
