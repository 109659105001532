import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { NumberOnlyDirective } from "./number-only.directive";
import { RequiredAsteriskDirective } from "./require-asterisk.directive";
import { TypeSafeMatCellDef } from "./type-safe-mat-cell-def.directive";
import { ThousandsSeparatorDirective } from "./thousands-separator.directive";

@NgModule({
    declarations: [NumberOnlyDirective, RequiredAsteriskDirective, TypeSafeMatCellDef, ThousandsSeparatorDirective],
    imports: [CommonModule],
    exports: [NumberOnlyDirective, RequiredAsteriskDirective, TypeSafeMatCellDef, ThousandsSeparatorDirective],
    providers: [DecimalPipe]
})
export class DirectiveModule {}
