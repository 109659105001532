import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { firstValueFrom } from "rxjs";
import { ApproverListResponse } from "../../../api/q2o/request/models/approver-list.response";
import { CreateCostStructureService } from "../../../api/q2o/request/services/create-cost-structure.service";
import { AuthLevelMapping } from "../../../constant/create-wf.constant";
import { selectRequestIdAndProcessId } from "../../../store/selectors/create-q2o-request.selector";
import { selectFWORequestIdAndProcessId } from "../../../store/selectors/create-fwo-request.selector";

@Component({
    selector: "app-approver-list",
    templateUrl: "./approver-list.component.html",
    styleUrl: "./approver-list.component.scss",
})
export class ApproverListComponent implements OnInit {
    @Input() isQ2O: boolean = true;
    data: ApproverListResponse[] = [];
    constructor(
        private createCostStructureService: CreateCostStructureService,
        private store: Store,
    ) { }

    ngOnInit() {
        this.fetchApproverList();
    }

    async fetchApproverList() {
        const selectorToUse = this.isQ2O ? selectRequestIdAndProcessId : selectFWORequestIdAndProcessId;
        const { requestId, processId } = await firstValueFrom(this.store.select(selectorToUse));
        if(processId){
            this.createCostStructureService.getApproverList(requestId, processId).subscribe(
                {
                    next: (res) => {
                        this.data = res.data;
                    },
                    error: (err) => {

                    }
                }
            );
        }
    }

    getMappedAuthLevel(authLevel: number | null): string {
      return AuthLevelMapping[authLevel ?? ""] ?? "-";
    }
}
