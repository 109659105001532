import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "./material/material.module";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { DirectiveModule } from "./directives/directive.module";
import { FileInputComponent } from "./components/file-input/file-input.component";
import { AutoCompleteComponent } from "./components/auto-complete/auto-complete.component";
import { RequesterInformationComponent } from "./components/requester-information/requester-information.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TableFileUploadComponent } from "./components/table-file-upload/table-file-upload.component";
import { TableFileUploadWithNameComponent } from "./components/table-file-upload-with-name/table-file-upload-with-name.component";
import { PicklistComponent } from './components/picklist/picklist.component';
import { AwsApproverListComponent } from "./components/aws-approver-list/aws-approver-list.component";
import { AwsErrorInfoComponent } from "./components/aws-error-info/aws-error-info.component";
import { FwuFwdTableComponent } from "./components/fwu-fwd-table/fwu-fwd.table.component";
import { AutocompleteControlComponent } from "./components/mat-auto-complete/mat-auto-complete.component";
import { ApproverListComponent } from "./components/approver-list/approver-list.component";

@NgModule({
    declarations: [
        FileInputComponent,
        AutoCompleteComponent,
        RequesterInformationComponent,
        TableFileUploadComponent,
        TableFileUploadWithNameComponent,
        PicklistComponent,
        AwsApproverListComponent,
        AwsErrorInfoComponent,
        FwuFwdTableComponent,
        AutocompleteControlComponent,
        ApproverListComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        ScrollingModule,
        FormsModule,
        DirectiveModule
    ],
    exports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        DirectiveModule,
        FileInputComponent,
        AutoCompleteComponent,
        RequesterInformationComponent,
        ScrollingModule,
        TableFileUploadComponent,
        TableFileUploadWithNameComponent,
        PicklistComponent,
        AwsApproverListComponent,
        AwsErrorInfoComponent,
        FwuFwdTableComponent,
        AutocompleteControlComponent,
        ApproverListComponent
    ],
})
export class SharedModule { }
