import { Injectable } from "@angular/core";
import { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import * as Swal from "sweetalert2/src/sweetalert2.js";

@Injectable({ providedIn: "root" })
export class AlertService {
    baseOptions: SweetAlertOptions = {
        confirmButtonText: "OK",
        allowOutsideClick: false,
        heightAuto: false,
    };

    constructor() {}

    error(text: string, title = "Error", callback?: () => void) {
        Swal.default
            .fire({ ...this.baseOptions, title: title, html: text, icon: "error" })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callback) {
                    callback();
                }
            });
    }

    technicalError(text?: string) {
        let msg = "Please try again shortly. If the error still persists, please contact the administrator.";
        if(text) {
            msg += `<br><br>Error message: <em>${text}</em>` 
        };
        Swal.default
            .fire({ ...this.baseOptions, title: "Technical Error", html: msg, icon: "error" });
    }

    errorBullets(errors: string[], title = "Error", callback?: () => void) { 
        let li = "";
        for(let e of errors) {
            li+= `<li>${e}</li><br>`
        }
        const finalString = `<ul style="text-align: left">${li}</ul>`;
        this.error(finalString, title, callback);
    }

    errorWithPrefix(text: string, title = "Error") {
        this.error(`Error occured when ${text}`, title);
    }

    warning(text: string, title = "Warning", callback?: () => void) {
        Swal.default
            .fire({ ...this.baseOptions, title: title, html: text, icon: "warning" })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callback) {
                    callback();
                }
            });
    }

    info(text: string, title = "Info", callback?: () => void) {
        Swal.default
            .fire({ ...this.baseOptions, title: title, html: text, icon: "info" })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callback) {
                    callback();
                }
            });
    }

    success(text: string, title = "Success", callback?: () => void) {
        Swal.default
            .fire({ ...this.baseOptions, title: title, html: text, icon: "success" })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callback) {
                    callback();
                }
            });
    }

    successWithPrefix(text: string, title = "Success", callback?: () => void) {
        return this.success(`Successfully ${text}`, title, callback);
    }

    yesno(text = "Are you sure?", title = "Warning", callback?: () => void) {
        Swal.default
            .fire({
                ...this.baseOptions,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                title: title,
                html: text,
                icon: "warning",
                showCancelButton: true,
            })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callback) {
                    callback();
                } else if (result.isDenied || result.isDenied) {
                    // Do something
                }
            });
    }

    yesnoWithInput(
        text = "Are you sure?",
        title = "Warning",
        placeHolder: string,
        inputValidator: (result: string) => void,
        callback?: (value: string) => void,
    ) {
        Swal.default
            .fire({
                ...this.baseOptions,
                input: "text",
                inputValidator: inputValidator,
                inputPlaceholder: placeHolder,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                title: title,
                html: text,
                icon: "warning",
                showCancelButton: true,
            })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callback) {
                    callback(result.value);
                } else if (result.isDenied || result.isDenied) {
                    // Do something
                }
            });
    }

    html(html: string, title: string, icon: SweetAlertIcon) {
        Swal.default.fire({ ...this.baseOptions, title: title, html: html, icon: icon });
    }

    loading(text: string, title: string) {
        Swal.default.fire({
            text: text,
            title: title,
            showConfirmButton: false,
            backdrop: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
    }

    timeout(callback?: any) {
        Swal.default
            .fire({
                ...this.baseOptions,
                title: "Session Timeout",
                html: "You will be redirected to the logout page.",
                icon: "error",
                backdrop: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
            })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callback) {
                    callback();
                }
            });
    }

    forbidden(text: string, callback?: () => void) {
        this.error(text, "403: Forbidden", callback);
    }

    close() {
        Swal.default.close();
    }

    saveNotSaveCancel(text = "Do you want to save the changes?", title = "Warning", callbackSave?: () => void, callbackNotSave?: () => void) {
        Swal.default
            .fire({
                ...this.baseOptions,
                confirmButtonText: "Save",
                cancelButtonText: "Cancel",
                denyButtonText: "Don't Save",
                title: title,
                html: text,
                icon: "warning",
                showCancelButton: true,
                showDenyButton: true,
            })
            .then((result: SweetAlertResult) => {
                if (result.isConfirmed && callbackSave) {
                    callbackSave();
                } else if (result.isDenied && callbackNotSave) {
                    callbackNotSave();
                }
                else {
                    // Do something
                }
            });
    }

    successMixin(text: string, title: string, callback?: () => void) {
        const Toast = Swal.default.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.default.stopTimer;
              toast.onmouseleave = Swal.default.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: title,
            text: text,
          }).then(() => {
            if (callback) {
                callback();
            }
          });
    }

    successMixinWithPrefix(text: string, title = "Success", callback?: () => void) {
        return this.successMixin(`Successfully ${text}`, title, callback);
    }
}
