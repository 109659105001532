import { Component, Input, OnInit } from "@angular/core";
import { CheckApproverResponseDto, CheckDoaResponseDto, CreateWorkflowResponse } from "../../../api/q2o/q2o-request/create-coststructure/models/create-wf-response.model";
import { APIResponse } from "../../../api/common/models/api-response.model";

@Component({
    selector: "app-aws-error-info",
    templateUrl: "./aws-error-info.component.html",
    styleUrl: "./aws-error-info.component.scss",
})
export class AwsErrorInfoComponent implements OnInit {
    @Input() checkOrCreateWorkflowResponse!: APIResponse<CreateWorkflowResponse | CheckDoaResponseDto | CheckApproverResponseDto>;
    checkDoaError!: APIResponse<CheckDoaResponseDto>;
    checkApproverError!: APIResponse<CheckApproverResponseDto>;

    constructor() { }

    ngOnInit(): void {
        const res = this.checkOrCreateWorkflowResponse;
        if (!res.isSuccess) {
            const isCheckDoaError = res.data.hasOwnProperty("topics");
            const isApproverError = res.data.hasOwnProperty("approval_workflows");
            if (isCheckDoaError) {
                this.checkDoaError = res as APIResponse<CheckDoaResponseDto>;
            }
            else if (isApproverError) {
                this.checkApproverError = res as APIResponse<CheckApproverResponseDto>;
            }
        }
    }
}

