import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatStepperModule } from "@angular/material/stepper";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { OverlayModule } from "@angular/cdk/overlay";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatDialogModule } from "@angular/material/dialog";
import { MatChipsModule } from "@angular/material/chips";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";

@NgModule({
    declarations: [],
    exports: [
        MatButtonModule,
        MatMenuModule,
        MatSidenavModule,
        MatCardModule,
        MatExpansionModule,
        MatGridListModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSelectModule,
        MatIconModule,
        MatDividerModule,
        MatListModule,
        MatTabsModule,
        OverlayModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatDialogModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        DragDropModule,
        MatProgressSpinnerModule,
        ScrollingModule,
    ],
    providers: [
        MatDatepickerModule,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: "DD/MM/YYYY", // this is how your date will be parsed from Input
                },
                display: {
                    dateInput: "DD/MM/YYYY", // this is how your date will get displayed on the Input
                    monthYearLabel: "MMMM YYYY",
                    dateA11yLabel: "LL",
                    monthYearA11yLabel: "MMMM YYYY",
                },
            },
        },
        // {
        //   provide: STEPPER_GLOBAL_OPTIONS,
        //   useValue: { displayDefaultIndicatorType: true }
        // }
    ],
})
export class MaterialModule {}
