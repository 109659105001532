import { Component, EventEmitter, Input, Output, SimpleChange } from "@angular/core";
import { FormControl, FormGroupDirective } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { Observable, map, startWith } from "rxjs";

@Component({
    selector: "app-auto-complete",
    templateUrl: "./auto-complete.component.html",
    styleUrl: "./auto-complete.component.scss",
})
export class AutoCompleteComponent {
    constructor(private rootFormGroup: FormGroupDirective) {}

    //----------------------------  Input / Output  --------------------------------//
    @Input() controlName!: string;
    @Input() model!: any;
    @Input() display!: string;
    @Input() value!: string;
    @Input() options: any[] = [];
    @Input() disabled: boolean = false;
    @Input() readonly: boolean = false;
    @Input() className: string = "";

    @Output() modelChange = new EventEmitter<any>();
    @Output() optionSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

    //----------------------------  Valiable  --------------------------------//
    form!: FormControl;
    filteredOptions!: Observable<any[]>;

    //----------------------------  Get / Set  --------------------------------//
    get formValue() {
        return this.form.getRawValue();
    }

    set formValue(input: any) {
        this.form.patchValue(input);
    }

    //#region Life cycle
    ngOnInit(): void {
        if (this.controlName) {
            this.form = this.rootFormGroup.control.get(this.controlName) as FormControl;
        } else {
            this.form = new FormControl("");
        }
        this.form.valueChanges.subscribe((vc) => this.modelChange.emit(vc));
        this.filteredOptions = this.form.valueChanges.pipe(
            startWith(""),
            map((value) => {
                const name = value ? (typeof value === "string" ? value : value[this.display]) : null;
                return name ? this._filter(name as string) : this.options.slice();
            }),
        );
    }

    ngOnChanges(changes: { [propName: string]: SimpleChange }) {
        if (changes["disabled"]) {
            if (changes["disabled"].currentValue) {
                this.form.disable();
            } else {
                this.form.enable();
            }
        }
    }

    displayFn(input: any): string {
        return input && input[this.display] ? input[this.display] : "";
    }

    private _filter(name: string): any[] {
        const filterValue = name.toLowerCase();

        if (!!this.display) {
            return this.options.filter((option) => option[this.display].toLowerCase().includes(filterValue));
        }
        return this.options.filter((option) => option.toLowerCase().includes(filterValue));
    }
    //#endregion

    //#region handle event function
    onSelectChange(event?: MatAutocompleteSelectedEvent) {
        this.optionSelected.emit(event);
    }
    //#endregion
}
