import { Directive, OnInit, ElementRef, HostListener, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { AbstractControl, FormControl, NgControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { take } from 'rxjs';

@Directive({
  selector: '[thousands-separator]',
})
export class ThousandsSeparatorDirective implements OnInit {
  // build the regex based on max pre decimal digits allowed
  private regexString(max?: number | null, maxFraction?: number | null, minFraction?: number | null) {
    const maxStr = max ? `{0,${max}}` : `+`;
    let fractionStr = '{0,999}';
    // if(maxFraction && minFraction) {
    //   fractionStr = `{${minFraction},${maxFraction}}`;
    // }
    // else if(maxFraction && !minFraction) {
    //   fractionStr = `{0,${maxFraction}}`;
    // }
    if(this.maxFractionDigit) {
      fractionStr = `{0,${maxFraction}}`;
    }
    else if(!this.maxFractionDigit && this.minFractionDigit) {
      // fractionStr = `{${minFraction},}`;
      fractionStr = `{0,999}`;
    }
    return `^(-?(\\d${maxStr}(\\.\\d${fractionStr})?|\\.\\d${fractionStr}))$`;
  }
  private digitRegex!: RegExp;
  private setRegex(maxDigits?: number | null, maxFraction?: number | null, minFraction?: number | null) {
    this.digitRegex = new RegExp(this.regexString(maxDigits, maxFraction, minFraction), 'g');
  }
  private minFractionDigit?: number;
  private maxFractionDigit?: number;
  @Input()
  set maxDigits(maxDigits: number) {
    this.setRegex(maxDigits);
  }
  @Input()
  set maxFractionDigits(maxDigits: number) {
    this.maxFractionDigit = maxDigits;
    this.setRegex(null, maxDigits);
  }
  @Input()
  set minFractionDigits(minDigits: number) {
    this.minFractionDigit = minDigits;
    this.setRegex(null, this.maxFractionDigit, minDigits);
  }
  @Input()
  set maxValue(max: number | null) {
    this.max = max;
    const abstractControl = this.control.control;
    if (!!abstractControl && max !== null) {
      abstractControl.addValidators([() => +this.formatOnFocus(abstractControl.getRawValue()) > max ? {max: true} : null])
    }
  }
  @Input()
  set minValue(min: number | null) {
    this.min = min;
    const abstractControl = this.control.control;
    if (!!abstractControl && min !== null) {
      abstractControl.addValidators([() => +this.formatOnFocus(abstractControl.getRawValue()) < min ? {min: true} : null])
    }
  }
  private min?: number | null = null;
  private max?: number | null = null;

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private decimalPipe: DecimalPipe,
    private control: NgControl
  ) {
    this.el = this.elementRef.nativeElement;
    this.setRegex();
  }

  ngOnInit() {
    const abstractControl = this.control.control;
    if (!!abstractControl) {
      abstractControl.valueChanges.pipe(take(1)).subscribe((x) => {
        this.el.value = this.formatOnBlur(this.el.value, x, true)!;
      });
      const validateMax: ValidatorFn = () => +this.formatOnFocus(abstractControl.getRawValue()) > this.max! ? {max: true} : null;
      const validateMin: ValidatorFn = () => +this.formatOnFocus(abstractControl.getRawValue()) < this.min! ? {min: true} : null;
      if (this.max !== null) {
        abstractControl.addValidators([validateMax]);
      }
      if (this.min !== null) {
        abstractControl.addValidators([validateMin]);
      }
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string) {
    this.el.value = this.formatOnFocus(value);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.el.value = this.formatOnBlur(this.el.value, value)!;
    const abstractControl = this.control.control;
    if (!!abstractControl) {
      abstractControl.patchValue(this.el.value);
    }
  }

  /**
   * on focus, remove currency formatting
   * @param value
   * @returns
   */
   private formatOnFocus(value: string): string {
    if (!value) return '';
    let arrNumber = value.split(',');

    return value.replace(/\,/g, '');
  }

  /**
   * on blur, add currency formatting
   * @param value
   * @param incognitValue
   * @returns
   */
  private formatOnBlur(value: string | null, incognitValue: string, isFirstChange?: boolean): string | null {
    if (!value) return '';
    
    if (isFirstChange) {
      value = value.replaceAll(',', '');
    }
    let format = '';
    let minDigitStr = '0';
    if (this.minFractionDigit && !isFirstChange) {
      minDigitStr = `${this.minFractionDigit}`;
    }
    if(this.maxFractionDigit) {
      format = `1.0-${this.maxFractionDigit}`
      value = this.decimalPipe.transform(value, `1.${minDigitStr}-${this.maxFractionDigit}`);
    }
    else {
      value = this.decimalPipe.transform(value, `1.${minDigitStr}-999`);
    }
    if (isFirstChange && incognitValue.indexOf('.') === incognitValue.length - 1) {
      value = `${value}.`
    }
    // if (isFirstChange) {
    //   this.lastValid = this.formatOnFocus(value!);
    // }
    
    return value;
  }

  // variable to store last valid input
  private lastValid = '';
  @HostListener('input', ['$event'])
  onInput(event: any) {
    // on input, run regex to only allow certain characters and format
    const value = event.target.value ?? "";
    let cleanValue = (event.target.value.match(this.digitRegex) || []).join('');
    
    if (!cleanValue && String(value).includes(',')) {
      cleanValue = (value.replaceAll(',', '').match(this.digitRegex) || []).join('');
    }
    if (cleanValue || !event.target.value) this.lastValid = cleanValue;
    this.el.value = cleanValue || this.lastValid;
  }

  // validate(control: AbstractControl): ValidationErrors | null {
  //   const formControl = control as FormControl;
  //   console.log(formControl);
    
  //   if (!(formControl instanceof AbstractControl)) {
  //       return null;
  //   }

  //   if(this.max !== undefined && this.max !== null) {
  //     return +this.formatOnFocus(formControl.getRawValue()) > this.max ? {max: true} : null;
  //   }
  //   if(this.min !== undefined && this.min !== null) {
  //     return +this.formatOnFocus(formControl.getRawValue()) < this.min ? {min: true} : null;
  //   }

  //   return null;
  // }
}