<table class="mat-table-style mt-4">
    <thead>
        <tr>
            <th class="mat-mdc-header-cell">Step</th>
            <th class="mat-mdc-header-cell">Cost Center</th>
            <th class="mat-mdc-header-cell">Auth Level</th>
            <th class="mat-mdc-header-cell">Position</th>
            <th class="mat-mdc-header-cell">Name</th>
            <th class="mat-mdc-header-cell">Email</th>
        </tr>
    </thead>
    <tbody *ngIf="authLevelMapping$ | async as mapping">
        <tr *ngFor="let t of tasks">
            <td class="mat-mdc-cell">{{t.step_no}}</td>
            <td class="mat-mdc-cell">{{t.node_id}}</td>
            <td class="mat-mdc-cell">{{t.doa_auth_level}}</td>
            <td class="mat-mdc-cell">
                @if(t.is_group_approval) {
                {{t.group_of_member_name_en}}
                }
                @else {
                {{getMappedAuthLevel(mapping, t.doa_auth_level)}}
                }
            </td>
            <td class="mat-mdc-cell">{{t.designated_approver_name}}</td>
            <td class="mat-mdc-cell">{{t.designated_approver}}</td>
        </tr>
    </tbody>
</table>