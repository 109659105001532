import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateFWOState } from "../reducers/create-fwo-request.reducer";

const state = createFeatureSelector<CreateFWOState>("createFWOState");


export const selectFWORequestId = createSelector(
    state,
    (state: CreateFWOState) => state.requestId
);

export const selectFWOProcessId = createSelector(
    state,
    (state: CreateFWOState) => state.processId
);

export const selectFWORequesterEmail = createSelector(
    state,
    (state: CreateFWOState) => state.requesterEmail
);

export const selectFWDNewRequestFlag = createSelector(
    state,
    (state: CreateFWOState) => state.isNewRequest
);

export const selectFWDAuthorizationInfo = createSelector(
    state,
    (state: CreateFWOState) => state.authorizationInfo
);

export const selectFWORequestIdAndProcessId = createSelector(
    selectFWORequestId,
    selectFWOProcessId,
    (requestId, processId) => ({requestId, processId})
);
