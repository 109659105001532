import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CreateQ2OState } from "../reducers/create-q2o-request.reducer";

const state = createFeatureSelector<CreateQ2OState>("createQ2OState");

export const selectAllCreateQ2OState = createSelector(
    state,
    (state: CreateQ2OState) => state
);

//#region Single selectors
export const selectRequestId = createSelector(
    state,
    (state: CreateQ2OState) => state.requestId
);

export const selectProcessId = createSelector(
    state,
    (state: CreateQ2OState) => state.processId
);

export const selectDisChannel = createSelector(
    state,
    (state: CreateQ2OState) => state.disChannelCode
);

export const selectRequesterComCode = createSelector(
    state,
    (state: CreateQ2OState) => state.requesterCompCode
);

export const selectRequesterEmail = createSelector(
    state,
    (state: CreateQ2OState) => state.requesterEmail
);

export const selectRequesterCostCenter = createSelector(
    state,
    (state: CreateQ2OState) => state.requesterCostCenter
);

export const selectRequesterInfoFormState = createSelector(
    state,
    (state: CreateQ2OState) => state.requesterInforFormValid,
);

export const selectProductGroupState = createSelector(state, (state: CreateQ2OState) => state.productGroup);

export const selectTrnGroup = createSelector(state, (state: CreateQ2OState) => state.trnGroup);

export const selectTrnPractice = createSelector(state, (state: CreateQ2OState) => state.practiceCode);

export const selectTrnPracticeName = createSelector(state, (state: CreateQ2OState) => state.practiceName);

export const selectStatusId = createSelector(
    state,
    (state: CreateQ2OState) => state.statusId
);

export const selectRequestNo = createSelector(
    state,
    (state: CreateQ2OState) => state.requestNo
);

export const selectHaveBookForward = createSelector(
    state,
    (state: CreateQ2OState) => state.haveBookForward
);

export const selectIsSkipQuotation = createSelector(
    state,
    (state: CreateQ2OState) => state.isSkipQuotation
);

export const selectAuthorizationInfo = createSelector(
    state,
    (state: CreateQ2OState) => state.authorizationInfo
);

export const selectQ2ONewRequestFlag = createSelector(
    state,
    (state: CreateQ2OState) => state.isNewRequest
);

export const selectTrnStockType = createSelector(
    state,
    (state: CreateQ2OState) => state.trnStocktype
);

//#endregion

//#region Combined selectors
export const selectRequestIdAndProcessId = createSelector(
    selectRequestId,
    selectProcessId,
    (requestId, processId) => ({ requestId, processId })
);

export const selectDisChannelCodeAndComCode = createSelector(
    selectDisChannel,
    selectRequesterComCode,
    (disChannelCode, comCode) => ({
        disChannelCode: disChannelCode,
        comCode: comCode
    })
);

export const selectRequesterEmailAndCompCodeAndCostCenter = createSelector(
    selectRequesterEmail,
    selectRequesterComCode,
    selectRequesterCostCenter,
    (email, comCode, costCenter) => ({
        requesterEmail: email,
        requesterCompCode: comCode,
        requesterCostCenter: costCenter
    }),
);

export const selectProcessIdAndTrnPractice = createSelector(
    selectProcessId,
    selectTrnPractice,
    (processId, trnPractice) => ({ processId, trnPractice })
);

export const selectRequestIdAndProcessIdAndComCode = createSelector(
    selectRequestId,
    selectProcessId,
    selectRequesterComCode,
    (requestId, processId, comCode) => ({ requestId, processId, comCode })
);

export const selectTrnPracticeAndComCode = createSelector(
    selectTrnPractice,
    selectRequesterComCode,
    (trnPractice, comCode) => ({ trnPractice, comCode })
);

//#endregion
