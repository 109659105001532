import { Component, Input, OnInit } from "@angular/core";
import { AuthLevelMapping } from "../../../constant/create-wf.constant";
import { SubmitQ2OService } from "../../../api/q2o/request/services/submit-q2o/submit-q2o.service";
import { AuthLevelMappingResponse } from "../../../api/q2o/request/services/submit-q2o/auth-level-mapping.response";
import { Task } from "../../../api/q2o/q2o-request/create-coststructure/models/create-wf-response.model";

@Component({
    selector: "app-aws-approver-list",
    templateUrl: "./aws-approver-list.component.html",
    styleUrl: "./aws-approver-list.component.scss",
})
export class AwsApproverListComponent {
    @Input() tasks: Task[] = [];
    authLevelMapping$ = this.submitQ2OService.getAuthLevelMapping();

    constructor(
        private submitQ2OService: SubmitQ2OService
    ) { }

    getMappedAuthLevel(mapping: AuthLevelMappingResponse[], authLevel: number | null): string {
        if (mapping.length > 0) {
            const matched = mapping.find((i) => i.auth_level == authLevel);
            if (matched) {
                return matched.auth_level_description;
            }
        }
        return AuthLevelMapping[authLevel ?? ""] ?? "-";
    }
}

