import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { APIUrlToUse } from "../../../../../enums/api-url-to-use.enum";
import { BaseService, ServiceName } from "../../../../../services/base-service";
import { selectRequestIdAndProcessId } from "../../../../../store/selectors/create-q2o-request.selector";
import { Observable, catchError, map, of, switchMap, take } from "rxjs";
import { FWUResponseDto } from "./fwu-fwo-dollar-link.response";
import { APIResponse } from "../../../../common/models/api-response.model";
import { SaveFwuFwoDollarLinkRequest } from "./save-fwu-fwo-dollar-ink.request";
import { AuthLevelMappingResponse } from "./auth-level-mapping.response";
import { CheckFlagAndUpdateStatuResponseDto } from "./check-flag-and-update-status.response";
import { CreateWorkflowResponse } from "../../../q2o-request/create-coststructure/models/create-wf-response.model";
import { FinalSubmitIndentResponseDto } from "../../models/final-submit-indent.response";
import { SaveQ2ORequest } from "../../models/create-cost-structure.model";
import { SubmitIndentResponse } from "../../models/submit-indent.response";
import { SubmitQ2OResponse } from "../../models/submit-q2o-response.model";
import { TestRunResultResponse } from "./test-run-result.response";

@Injectable({
    providedIn: "root",
})
export class SubmitQ2OService extends BaseService {
    constructor(
        private http: HttpClient,
        private store: Store,
    ) {
        super(APIUrlToUse.deployed, "http://localhost:3002", ServiceName.q2o);
    }

    private readonly url = {
        getFwuFwoDollarLink: "/submit-q2o/fwu-fwo-dollar-link",
        saveFwuFwoDollarLink: "/submit-q2o/save-fwu-fwo-dollar-link",
        getAuthLevelMapping: "/submit-q2o/auth-level-mapping",
        checkFlagAndUpdateStatus: "/submit-q2o/check-flags-and-update-status",
        finalSubmit: "/submit-q2o/final-submit",
        finalSubmitIndent: "/submit-q2o/final-submit-indent",
        checkWorkflow: "/submit-q2o/check-workflow",
        submitIndent: "/submit-q2o/submit-indent",
        submit: "/submit-q2o/submit",
        testRun: "/submit-q2o/test-run",
    };

    private get getFwuFwoDollarLinkUrl() {
        return this.prepareUrl(this.url.getFwuFwoDollarLink);
    }
    private get saveFwuFwoDollarLinkUrl() {
        return this.prepareUrl(this.url.saveFwuFwoDollarLink);
    }
    private get getAuthLevelMappingUrl() {
        return this.prepareUrl(this.url.getAuthLevelMapping);
    }
    private get checkFlagAndUpdateStatusUrl() {
        return this.prepareUrl(this.url.checkFlagAndUpdateStatus);
    }
    private get checkWorkflowUrl(): string {
        return this.prepareUrl(this.url.checkWorkflow);
    }
    private get finalSubmitUrl(): string {
        return this.prepareUrl(this.url.finalSubmit);
    }
    private get finalSubmitIndentUrl(): string {
        return this.prepareUrl(this.url.finalSubmitIndent);
    }
    private get submitIndentUrl(): string {
        return this.prepareUrl(this.url.submitIndent);
    }
    private get submitUrl(): string {
        return this.prepareUrl(this.url.submit);
    }
    private get testRunUrl(): string {
        return this.prepareUrl(this.url.testRun);
    }

    submit(body: SaveQ2ORequest): Observable<APIResponse<SubmitQ2OResponse>> {
        return this.http.post<APIResponse<SubmitQ2OResponse>>(this.submitUrl, body);
    }

    testRun(requestId: number): Observable<APIResponse<TestRunResultResponse>> {
        return this.http.post<APIResponse<TestRunResultResponse>>(this.testRunUrl, {
            request_id: requestId
        });
    }

    submitIndent(body: SaveQ2ORequest): Observable<APIResponse<SubmitIndentResponse>> {
        return this.http.post<APIResponse<SubmitIndentResponse>>(this.submitIndentUrl, body);
    }

    checkWorkflow() {
        return this.store.pipe(
            select(selectRequestIdAndProcessId),
            take(1),
            switchMap((v) => {
                const body = {
                    request_id: v.requestId,
                    process_id: v.processId
                };
                return this.http.post<APIResponse<CreateWorkflowResponse>>(this.checkWorkflowUrl, body);
            }),
        );
    }

    finalSubmit(cc_email : string[]): Observable<APIResponse<any>> {
        return this.store.pipe(
            select(selectRequestIdAndProcessId),
            take(1),
            switchMap((v) => {
                const body = {
                    request_id: v.requestId,
                    process_id: v.processId,
                    cc_email: cc_email
                };
                return this.http.post<APIResponse<any>>(this.finalSubmitUrl, body);
            }),
        );
    }

    finalSubmitIndent(): Observable<APIResponse<FinalSubmitIndentResponseDto>> {
        return this.store.pipe(
            select(selectRequestIdAndProcessId),
            take(1),
            switchMap((v) => {
                const body = {
                    request_id: v.requestId,
                    process_id: v.processId
                };
                return this.http.post<APIResponse<FinalSubmitIndentResponseDto>>(this.finalSubmitIndentUrl, body);
            }),
        );
    }

    getFwuFwoDollarLink() {
        return this.store.select(selectRequestIdAndProcessId).pipe(
            take(1),
            switchMap(({ requestId, processId }) => {
                const httpParam = this.prepareRequestIdAndProcessIdQueryParam(requestId, processId);
                return this.http.get<APIResponse<FWUResponseDto>>(this.getFwuFwoDollarLinkUrl, { params: httpParam });
            })
        );
    }

    saveFwuFwoDollarLink(req: SaveFwuFwoDollarLinkRequest) {
        return this.http.post<APIResponse<any>>(this.saveFwuFwoDollarLinkUrl, req);
    }

    getAuthLevelMapping() {
        return this.http.get<APIResponse<AuthLevelMappingResponse[]>>(this.getAuthLevelMappingUrl).pipe(
            map((i) => i.data),
            catchError((err) => of([]))
        );
    }

    checkFlagAndUpdateStatus() {
        return this.store.select(selectRequestIdAndProcessId).pipe(
            take(1),
            switchMap(({ requestId, processId }) => {
                const body = this.prepareRequestIdAndProcessIdBody(requestId, processId);
                return this.http.post<APIResponse<CheckFlagAndUpdateStatuResponseDto>>(this.checkFlagAndUpdateStatusUrl, body);
            })
        );
    }
}