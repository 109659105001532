import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
    selector: "[required-asterisk]",
})
export class RequiredAsteriskDirective implements OnInit {
    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        const ele = this.el.nativeElement;
        const existingHtml = ele.innerHTML;
        ele.innerHTML = existingHtml + '<span style="color:red; font-weight:bolder;"> *</span>';
    }
}
