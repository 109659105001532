import { IEnvironment } from "./IEnvironment";

export const environment: IEnvironment = {
    production: false,
    authRedirectUrl: "https://qasscgintl-eform.scg.com/home",
    postLogoutRedirectUri: "https://qasscgintl-eform.scg.com/login",
    loginFailedRoute: "https://qasscgintl-eform.scg.com/login",
    baseUrl: "",
    manuallyAddAuthHeaderForAllResources: false,
    clientId: "522a85d9-6c61-43a3-b780-dc1f32630434",
    authority: "https://login.microsoftonline.com/5db8bf0e-8592-4ed0-82b2-a6d4d77933d4",
    graphMicrosoft: "",
    API_MASTER_URL: "https://qasscgintl-eform.scg.com/api/master-service",
    API_Q2O_URL: "https://qasscgintl-eform.scg.com/api/q2o-service",
    API_FWD_URL: "https://qasscgintl-eform.scg.com/api/fwd-service",
    EIDC_URL: "http://10.42.133.101:8081/cm/login.do",
    BPPS_URL: "https://qasbpps.scg.com/",
    MMG_URL: "https://scgintl-mmg-ui-nonprd-dev-w4id6ofeda-as.a.run.app/#/inbox",
    TIME_OUT_MINUTE: 60,
    BULK_SO_PO_CANCELLATION_URL: 'bulk-cancle',
    RE_CREATE_ORDER_URL: 'create-order',
    ORDER_TRN_UNLOCKING_URL: 'https://qasscgt-eform.scg.co.th/UnlockingHelper.aspx',
};