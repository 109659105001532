import { Injectable } from "@angular/core";
import { ParsingOptions } from "xlsx";
import * as XLSX from "xlsx";
import { AlertService } from "./alert.service";
import { Subject } from "rxjs";

const EXCEL_MIME_TYPE = [
    //xlsx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //xlsm
    "application/vnd.ms-excel.sheet.macroEnabled.12",
];

export interface XlsxDataOutputEvent {
    data: XlsxData[];
    fileName: string;
    valid: boolean;
}

export interface XlsxData {
    sheetName: string;
    sheetData: any[];
    sheetDataExcelRow?: number[];
}

type AOA = any[][];

@Injectable({ providedIn: "root" })
export class ExcelParserService {
    private onParseSubject = new Subject<XlsxDataOutputEvent>();
    onParseAction$ = this.onParseSubject.asObservable();

    constructor(private alertService: AlertService) {}

    onFileChange(event: any, sheetname: string[] = []): void {
        const target: DataTransfer = <DataTransfer>event.target;
        if (target.files.length === 0) {
            return;
        }

        if (target.files.length !== 1) {
            this.alertService.error("Cannot read multiple file, please select 1 file only!", "Error");
            this.onParseSubject.next({
                data: [],
                fileName: "",
                valid: false,
            });
            return;
        }

        if (!this.checkFileType(event, EXCEL_MIME_TYPE)) {
            this.alertService.error("Please select Excel file (.xlsx, .xlsm) only!", "Error");
            this.onParseSubject.next({
                data: [],
                fileName: "",
                valid: false,
            });
            return;
        }

        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
            const data = this.readAndParseXlsxFileWithJavascriptThread(e, sheetname);

            this.onParseSubject.next({
                data: data,
                fileName: target?.files[0]?.name,
                valid: true,
            });
        };
        reader.readAsBinaryString(target.files[0]);
    }

    private readAndParseXlsxFileWithJavascriptThread(e: any, sheetname: string[]): XlsxData[] {
        const bstr: string = e.target.result;

        // specify options
        let options: ParsingOptions = { type: "binary" };
        if (sheetname?.length > 0) {
            options.sheets = sheetname;
        }
        const wb: XLSX.WorkBook = XLSX.read(bstr, options);
        const workSheetName = Object.keys(wb.Sheets);

        // push parsed data to array
        let tempData: XlsxData[] = [];

        workSheetName.forEach((item) => {
            let workSheet = wb.Sheets[item];
            tempData.push({
                sheetName: item,
                sheetData: <AOA>XLSX.utils.sheet_to_json(workSheet, { header: 1, blankrows: true, range: 0 }),
            });
        });

        return tempData;
    }

    private checkFileType(event: any, mimeType: string[]) {
        let flag: boolean = false;
        if (event?.target?.files) {
            let files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                if (mimeType.includes(files[i].type)) {
                    flag = true;
                }
            }
        }
        return flag;
    }
}
