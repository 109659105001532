import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({ selector: "[NumberOnly]" })
export class NumberOnlyDirective {
    private el: NgControl;

    constructor(private ngControl: NgControl) {
        this.el = ngControl;
    }

    private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

    private run(oldValue: any) {
        setTimeout(() => {
            let currentValue: string = this.el.control!.value;
            if (currentValue !== "" && !String(currentValue).match(this.regex)) {
                this.el.control!.setValue(oldValue);
            }
        });
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        this.run(this.el.control!.value);
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        this.run(this.el.control!.value);
    }
}
