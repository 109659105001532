import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from "file-saver";
import { FileSize } from "../../enums/file-size.enum";

export interface FileModel {
    fileName: string;
    filePath?: string;
    fileExtension: string;
    filePathOriginal?: string;
    fileData?: any;
    fileContent?: any;
    fileSize?: number;
    fileBlob?: any;
    fileFlag?: number;
    fileId?: number;
    fileRemark?: string;
}

@Injectable({
    providedIn: "root",
})
export class FileService {
    constructor(private sanitizer: DomSanitizer) {}

    downloadFileBase64(fileBase64: string, mime: string, fileName: string) {
        let blob = this.convertBase64ToBlobData(fileBase64, mime);
        saveAs(blob, fileName);
    }

    newTabFileBase64(fileBase64: string, mime: string) {
        let blob = this.convertBase64ToBlobData(fileBase64, mime);
        let fileURL = window.URL.createObjectURL(blob);
        let win = window.open(fileURL, "_blank");
    }

    getMimeByExt(ext: string) {
        let extSp = ext.split(".");
        if (extSp.length > 1) {
            ext = "." + extSp[extSp.length - 1];
        }

        let extToMimes: any = {
            ".jpeg": "image/jpeg",
            ".jpg": "image/jpeg",
            ".png": "image/png",
            ".pdf": "application/pdf",
            ".xls": "application/vnd.ms-excel",
            ".xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ".doc": "application/msword",
            ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ".ppt": "application/vnd.ms-powerpoint",
            ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            ".csv": "text/csv",
            ".msg": "application/vnd.ms-outlook",
            ".zip": "application/zip",
            ".txt": "text/plain",
            jpeg: "image/jpeg",
            jpg: "image/jpeg",
            png: "image/png",
            pdf: "application/pdf",
            xls: "application/vnd.ms-excel",
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            doc: "application/msword",
            docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ppt: "application/vnd.ms-powerpoint",
            pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            csv: "text/csv",
            msg: "application/vnd.ms-outlook",
            zip: "application/zip",
            txt: "text/plain",
        };
        ext = ext.toLowerCase();
        if (extToMimes.hasOwnProperty(ext)) {
            return extToMimes[ext];
        }
        return "";
    }

    convertBase64ToBlobData(base64Data: string, contentType: string, sliceSize = 512) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    getBlobFileSanitizer(fileBase64: string, fileExt: string) {
        let mimeType = this.getMimeByExt(fileExt) as string;
        let objectURL = "data:" + mimeType + ";base64," + fileBase64;
        if (mimeType.startsWith("image")) {
            // return this.sanitizer.bypassSecurityTrustUrl(objectURL);
            return this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
        } else {
            return this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
        }
    }

    getImageUrl(fileBase64: string, fileExt: string) {
        let mimeType = this.getMimeByExt(fileExt) as string;
        let objectURL = "data:" + mimeType + ";base64," + fileBase64;
        return objectURL;
    }

    getFileType(fileName: string) {
        let types = fileName.split(".");
        let type = types[types.length - 1];
        return "." + type.toLowerCase();
    }

    getFileNameNoExt(fileName: string) {
        let types = fileName.split(".");
        let name = "";
        for (let i = 0; i < types.length - 1; i++) {
            if (i > 0) name += "." + types[i];
            else name += types[i];
        }
        return name;
    }

    validateFileType(fileName: string, acceptedType: string) {
        let fileType = this.getFileType(fileName);
        let types = acceptedType?.split(",");
        let isValid = false;
        types?.forEach((x) => {
            let chType = x.toLowerCase();
            if (fileType == chType) isValid = true;
        });
        return isValid;
    }

    saveFile(file: FileModel) {
        let base64String = "data:" + file.fileExtension + ";base64," + file.fileData;
        saveAs(base64String, file.fileName);
    }

    newTabFile(file: FileModel) {
        return this.newTabFileBase64(file.fileData, file.fileExtension);
    }

    bytesToSize(bytes: number): string {
        const units = ["byte", "kilobyte", "megabyte", "gigabyte", "terabyte"];

        const navigatorLocal = navigator.languages && navigator.languages.length >= 0 ? navigator.languages[0] : "en-US";
        const unitIndex = Math.max(0, Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1));

        return Intl.NumberFormat(navigatorLocal, {
            style: "unit",
            unit: units[unitIndex],
        }).format(bytes / 1024 ** unitIndex);
    }

    sizeToBytes(size: number, unit: FileSize): number {
        const units = [FileSize.UNIT_BYTE, FileSize.UNIT_KB, FileSize.UNIT_MB, FileSize.UNIT_GB, FileSize.UNIT_TB];
        return size * 1024 ** units.indexOf(unit);
    }

    convertDataURLToBase64(input: string) {
        return input.replace("data:", "").replace(/^.+,/, "");
    }
}
