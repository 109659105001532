<mat-form-field appearance="outline" class="w-full" [subscriptSizing]="subscriptSizing">
    <mat-label *ngIf="formName">{{formName}}</mat-label>
    <input type="text" matInput [matAutocomplete]="auto" [formControl]="searchControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith()" (optionSelected)="onSelected($event)" panelWidth="null" [autoActiveFirstOption]="true">
        @for (option of filtredOptions; track option) {
            <mat-option [value]="option" >
                {{displayFunctionForOption(option)}}
            </mat-option>
        }
        <ng-content></ng-content>
    </mat-autocomplete>
    <button mat-icon-button *ngIf="searchControl.getRawValue()" matSuffix aria-label="Clear"
        [disabled]="disabled"
        (click)="onBtnClearClick(); $event.stopPropagation();">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>