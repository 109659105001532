import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from "../reducers/user.reducer";

const state = createFeatureSelector<UserState>("userState");

export const selectAllUserState = createSelector(
    state,
    (state: UserState) => state
);

export const selectUserEmail = createSelector(
    state,
    (state: UserState) => state.email
);

export const selectUserFirstName = createSelector(
    state,
    (state: UserState) => state.first_name
);

export const selectUserLastName = createSelector(
    state,
    (state: UserState) => state.last_name
);

export const selectUserComCode = createSelector(
    state,
    (state: UserState) => state.comCode
);

export const selectPageIds = createSelector(
    state,
    (state: UserState) => state.pages.map(x => x.page_id)
);

export const selectPermissions = createSelector(
    state,
    (state: UserState) => state.permissions
);

export const selectUserEmailComCode = createSelector(
    selectUserEmail,
    selectUserComCode,
    (email, comCode) => ({ email, comCode })
);