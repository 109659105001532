import { Injectable } from "@angular/core";
import { GetDirectionDropdownResponse } from "../models/get-direction-dropdown-response.model";
import { BehaviorSubject, Observable, combineLatest, delay, firstValueFrom, forkJoin, map, of, shareReplay, switchMap, take } from "rxjs";
import { CommonDropdownService } from "../../../../common/services/common-dropdown.service";
import { APIResponse } from "../../../../common/models/api-response.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BaseService, ServiceName } from "../../../../../services/base-service";
import { APIUrlToUse } from "../../../../../enums/api-url-to-use.enum";
import { GetCurrencyDropdownResponse } from "../models/get-currency-dropdown-response.model";
import { CreateFWORequest } from "../models/create-fwo-request.model";
import { GetFwoResponse } from "../models/get-fwo-response.model";
import { GetBankDropdownResponse } from "../models/get-bank-dropdown-response.model";
import { DIRECTION } from "../../../../../enums/direction.enum";
import { GetIndicativeRateResponse } from "../models/get-indicative-rate.response";
import { CreateFWORequestResponse } from "../models/create-fwo-request-response.model";
import { GetAssignToDropdownResponse } from "../models/get-assign-to-dropdown-response.model";
import { CreateWorkflowResponse } from "../../../../q2o/q2o-request/create-coststructure/models/create-wf-response.model";
import { AuthService } from "../../../../auth/auth.service";
import { Store } from "@ngrx/store";
import { selectAllUserState } from "../../../../../store/selectors/user.selector";
import { Page } from "../../../../auth/model/user-profile.response";

@Injectable({
    providedIn: "root",
})
export class CreateFWORequestService extends BaseService {
    constructor(
        private http: HttpClient,
        private commonDropdownService: CommonDropdownService,
        private store : Store
    ) {
        super(APIUrlToUse.deployed, "http://localhost:3000", ServiceName.fwd);
    }
    private requesterSubject = new BehaviorSubject<string>("");
    private requesterSelectedAction$ = this.requesterSubject.asObservable();
    onRequesterSelected(requester: string) {
        this.requesterSubject.next(requester);
    }

    private comCodeSubject = new BehaviorSubject<string>("");
    private comCodeSelectedAction$ = this.comCodeSubject.asObservable();
    onComCodeSelected(comCode: string) {
        if (comCode !== this.comCodeSubject.value) {
            this.comCodeSubject.next(comCode);
        }
    }

    private readonly url = {
        direction: "/fwo-request/direction",
        currency: "/fwo-request/currency",
        bank: "/fwo-request/bank",
        getIndicativeRate: "/fwo-request/indicative-rate",
        createFWORequest: "/fwo-request/create",
        getFWO: "/fwo-request/",
        cancelFWORequest: "/fwo-request/cancel",
        checkCreateWorkflow: "/fwo-request/check-workflow",
        assignTo: "/fwo-request/get-assignto",
        getActionBtnLink: "/fwo-request/action-btn-link",
        cancel: "/fwo-request/cancel",
    };

    private get getDirectionUrl(): string {
        return this.prepareUrl(this.url.direction);
    }

    private get getCurrencyUrl(): string {
        return this.prepareUrl(this.url.currency);
    }

    private get getCreateFWORequestUrl(): string {
        return this.prepareUrl(this.url.createFWORequest);
    }

    private get getBankUrl(): string {
        return this.prepareUrl(this.url.bank);
    }

    private get getCheckCreateWorkflow(): string {
        return this.prepareUrl(this.url.checkCreateWorkflow);
    }

    private get getFWOUrl(): string {
        return this.prepareUrl(this.url.getFWO);
    }

    private get getIndicativeRateUrl(): string {
        return this.prepareUrl(this.url.getIndicativeRate);
    }

    private get getAssignToUrl(): string {
        return this.prepareUrl(this.url.assignTo);
    }

    private get getActionBtnLinkUrl(): string {
        return this.prepareUrl(this.url.getActionBtnLink);
    }

    private get getCancelUrl(): string {
        return this.prepareUrl(this.url.cancel);
    }

    requesters$ = this.commonDropdownService.getRequesters();

    select_state$ = this.store.select(selectAllUserState);


    direction$ = this.http.get<APIResponse<GetDirectionDropdownResponse[]>>(this.getDirectionUrl).pipe(
        map((i) => 
            i.data.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
        ),
        shareReplay(1),
    );

    // direction2$ = combineLatest({
    //     state: this.select_state$,
    //     direction: this.direction$
    // }).pipe(
    //     switchMap(({ state }) => {
    //         if (state) {
    //             console.log('state', state.pages)
    //             return this.direction$.pipe(
    //                 map((i) => 
    //                     // this.checkdirectionauthorize(i, state.pages)
    //                     i.filter(d => state.pages.find(y => y.page_name.toLowerCase().includes(d.description.toLowerCase()))).sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
    //             ),
    //                 shareReplay(1),
    //             );
    //         }
    //         return of([]);
    //     }),
    //     shareReplay(1),
    // );


    // getDirection(): Observable<APIResponse<GetDirectionDropdownResponse[]>> {
    //     return this.http.get<APIResponse<GetDirectionDropdownResponse[]>>(this.getDirectionUrl);
    // }

    // checkdirectionauthorize(data : GetDirectionDropdownResponse[], pages : Page[])  : GetDirectionDropdownResponse[]{
    //     console.log('data', data.filter(d => pages.find(y => y.page_name.toLowerCase().includes(d.description.toLowerCase()))).sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())))
    //     return data.filter(d => pages.find(y => y.page_name.toLowerCase().includes(d.description.toLowerCase()))).sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()))
    // }

    currency$ = this.http.get<APIResponse<GetCurrencyDropdownResponse[]>>(this.getCurrencyUrl).pipe(
        map((i) => i.data),
        shareReplay(1),
    );

    bank$ = this.http.get<APIResponse<GetBankDropdownResponse[]>>(this.getBankUrl).pipe(
        map((i) => i.data),
        shareReplay(1),
    );

    assignTo$ = combineLatest({
        requester: this.requesterSelectedAction$,
        comCode: this.comCodeSelectedAction$,
    }).pipe(
        switchMap(({ requester, comCode }) => {
            if (!!requester && !!comCode) {
                return this.getAssignTo(requester, comCode).pipe(
                    map((i) => i.data),
                    shareReplay(1),
                );
            }
            return of([]);
        }),
        shareReplay(1),
    );

    createFWORequest(body: CreateFWORequest): Observable<APIResponse<CreateFWORequestResponse>> {
        return this.http.post<APIResponse<CreateFWORequestResponse>>(this.getCreateFWORequestUrl, body);
    }

    checkCreateWorkflow(body: CreateFWORequest) {
        return this.http.post<APIResponse<CreateWorkflowResponse>>(
            this.getCheckCreateWorkflow,
            body,
        );
    }

    getFWOByProcessIdAndRequestId(processId: string, requestId: string): Observable<APIResponse<GetFwoResponse>> {
        const params = new HttpParams().set("process_id", processId).set("request_id", requestId);
        return this.http.get<APIResponse<GetFwoResponse>>(this.getFWOUrl, { params });
    }

    getIndicativeRate(direction: DIRECTION, currency: string): Observable<GetIndicativeRateResponse> {
        const params = new HttpParams().set("direction_id", direction).set("currency", currency);

        return this.http.get<APIResponse<GetIndicativeRateResponse>>(this.getIndicativeRateUrl, { params }).pipe(
            map((i) => i.data),
            delay(1000),
        );
    }

    getAssignTo(requester: string, comCode: string): Observable<APIResponse<GetAssignToDropdownResponse[]>> {
        const params = new HttpParams().set("requester", requester).set("comp_code", comCode);
        return this.http.get<APIResponse<GetAssignToDropdownResponse[]>>(this.getAssignToUrl, { params });
    }

    getActionBtnLink(requestId: number): Observable<APIResponse<string>> {
        const params = new HttpParams().set("request_id", requestId);
        return this.http.get<APIResponse<string>>(this.getActionBtnLinkUrl, { params }).pipe(delay(1000));
    }

    cancelRequest(requestId: number, processId: string): Observable<APIResponse<never>> {
        const body = {
            request_id: requestId,
            process_id: processId
        };
        return this.http.post<APIResponse<never>>(this.getCancelUrl, body);
    }
}
