import { createAction } from "@ngrx/store";
import { SaveDraftToStore } from "../../modules/q2o/request/create-cost-structure/create-cost-structure.component";
import { AuthorizationInfoState } from "../reducers/create-q2o-request.reducer";

export const saveRequestIdAndProcessId = createAction(
    "[Create Q2O request] Select practice and click next -> save requestId and processId",
    (requestId: number, processId: string) => ({ requestId, processId }),
);

export const saveDraftResponse = createAction(
    "[Create Q2O request] Select practice and click next -> save draft response",
    (response: SaveDraftToStore) => response,
);

export const saveRequesterInfo = createAction(
    "[Create Q2O request] Select requester -> save email & comp code",
    (email: string, compCode: string, costCenter: string, isFormValid: boolean) => ({
        email,
        compCode,
        costCenter,
        isFormValid,
    }),
);

export const saveProductGroup = createAction(
    "[Create Q2O request] Select product group -> save product group",
    (productGroup: string) => ({ productGroup }),
);

export const updateRequestFormNo = createAction(
    "[Create Q2O request] Update request form no when requester information is saved",
    (requestFormNo: string) => ({ requestFormNo }),
);

export const resetQ2OState = createAction(
    "[Create Q2O request] Reset q2o state"
);

export const setHaveBookForwardFlag = createAction(
    "[Create Q2O request] Set haveBookForward",
    (haveBookForward: boolean) => ({ haveBookForward })
);

export const setIsSkipQuotationFlag = createAction(
    "[Create Q2O request] Set isSkipQuotation",
    (isSkipQuotation: boolean) => ({ isSkipQuotation })
);

export const setAuthorizationInfo = createAction(
    "[Create Q2O request] Set AuthorizationInfo",
    (authInfo: AuthorizationInfoState) => authInfo
);

export const setNewRequestFlag = createAction(
    "[Create Q2O request] Set ExistingRequest",
    (isNewRequest: boolean) => ({ isNewRequest })
);

export const setTrnStockType = createAction(
    "[Create Q2O request] Set ExistingRequest",
    (trnStocktype: string) => ({ trnStocktype })
);