import { APIUrlToUse } from "../enums/api-url-to-use.enum";
import { environment } from "../../environments/environment";
import { HttpParams } from "@angular/common/http";

export enum ServiceName {
    master = "/master",
    q2o = "/qwo",
    fwd = "/fwd",
}

export class BaseService {
    private env: APIUrlToUse;
    private localhostUrl: string;
    private serviceName: string;

    constructor(env: APIUrlToUse, localhostUrl: string, serviceName: ServiceName) {
        this.env = env;
        this.localhostUrl = localhostUrl;
        this.serviceName = serviceName;
    }

    /**
     * This is a helper method for preparing the backend URL, which can be localhost (backend running locally) or the deployed one.
     */
    prepareUrl(url: string): string {
        if (this.env === APIUrlToUse.localhost) {
            return this.localhostUrl + url;
        } else {
            switch (this.serviceName) {
                case ServiceName.master:
                    return environment.API_MASTER_URL + url;

                case ServiceName.q2o:
                    return environment.API_Q2O_URL + url;

                case ServiceName.fwd:
                    return environment.API_FWD_URL + url;

                default:
                    return "";
            }
        }
    }

    convertObjectToHttpQueryParams(object: any): HttpParams {
        let queryParams = new HttpParams();
        for (let key in object) {
            if (![null, undefined].includes(object[key])) {
                queryParams = queryParams.set(key, object[key]);
            }
        }
        return queryParams;
    }

    prepareRequestIdAndProcessIdQueryParam(requestId: number | string, processId: string) {
        return new HttpParams().set("request_id", requestId).set("process_id", processId);
    }

    prepareRequestIdAndProcessIdBody(requestId: number | string, processId: string) {
        const body = {
            request_id: Number(requestId),
            process_id: processId
        };
        return body;
    }
}
