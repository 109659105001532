import { Component } from '@angular/core';
import { MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-picklist',
  templateUrl: './picklist.component.html',
  styleUrl: './picklist.component.scss'
})
export class PicklistComponent {
  boxLeftData: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins'];
  boxRightData: string[] = ['Sneakers'];

  moveToRight(data?: MatListOption[]): void {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.boxRightData.push(data[i].value);
        this.boxLeftData = this.boxLeftData.filter((v) => v !== data[i].value);
      }
    } else {
      this.boxRightData = this.boxRightData.concat(this.boxLeftData);
      this.boxLeftData = [];
    }
  }

  moveToLeft(data?: MatListOption[]): void {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.boxLeftData.push(data[i].value);
        this.boxRightData = this.boxRightData.filter((v) => v !== data[i].value);
      }
    } else {
      this.boxLeftData = this.boxLeftData.concat(this.boxRightData);
      this.boxRightData = [];
    }
  }
}
