import { Component, Input, OnDestroy, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { XlsxDataOutputEvent, ExcelParserService } from "../../../services/utilities/excel-parser.service";

const EXCEL_MIME_TYPE: string =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12";
const YAML_MEME_TYPE: string = "application/x-yaml, text/yaml, text/x-yaml";
const PDF_NAME_TYPE: string = "application/pdf";

@Component({
    selector: "app-file-input",
    templateUrl: "./file-input.component.html",
    styleUrls: ["./file-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
})
export class FileInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() acceptFileType!: "excel" | "yaml" | "pdf";
    @Input() labelClass: string = "";
    accept!: string;
    isLoading: boolean = false;
    sub!: Subscription;
    fileName!: string;

    // ControlValueAccessor
    disabled: boolean = false;
    value!: XlsxDataOutputEvent | any;
    onChanged: any = () => {};
    onTouched: any = () => {};

    constructor(private excelParserService: ExcelParserService) {}

    ngOnDestroy(): void {
        this.unsubAndReset();
        // console.log("I'm destroyed");
    }

    unsubAndReset(): void {
        this.fileName = "";
        this.sub?.unsubscribe();
    }

    ngOnInit(): void {
        // console.log("I'm init");
        this.setAcceptFileType();
    }

    private setAcceptFileType() {
        if (this.acceptFileType == "excel") {
            this.accept = EXCEL_MIME_TYPE;
        } else if (this.acceptFileType == "yaml") {
            this.accept = YAML_MEME_TYPE;
        } else if (this.acceptFileType == "pdf") {
            this.accept = PDF_NAME_TYPE;
        }
    }

    onFileChange(event: any) {
        this.isLoading = true;
        this.fileName = event?.target?.files[0]?.name;
        if (this.acceptFileType == "excel") {
            this.parseExcel(event);
        } else if (this.acceptFileType == "yaml" || this.acceptFileType == "pdf") {
            this.fileName = event?.target?.files[0]?.name;
            this.setFile(event.target.files[0]);
        }
    }

    // When selecting the same file
    onChooseFileClick(event: any) {
        event.target.value = "";
        this.value = null;
        this.onChanged(null);
        this.onTouched();
        this.fileName = "";
    }

    parseExcel(event: any) {
        this.excelParserService.onFileChange(event);
        this.sub = this.excelParserService.onParseAction$.subscribe({
            next: (res: XlsxDataOutputEvent | any) => {
                // console.log(res);
                this.setFile(res);
                this.isLoading = false;
                this.sub.unsubscribe();
            },
            error: (err) => {
                this.setFile(null);
                this.isLoading = false;
                this.sub.unsubscribe();
            },
        });
    }

    // ControlValueAccessor Interfaces
    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    // Set file, when parsing is success
    setFile(file: any) {
        if (!this.disabled) {
            this.value = file;
            this.onChanged(file);
            this.onTouched();
        }
    }
}
