<div class="flex flex-row gap-x-3">
    <mat-card class="box-flex-basis">
        <mat-card-content>
            <mat-selection-list #boxLeft>
                @for (shoe of boxLeftData; track shoe) {
                <mat-list-option togglePosition="before" [value]="shoe">{{shoe}}</mat-list-option>
                }
            </mat-selection-list>
        </mat-card-content>
    </mat-card>
    <div class="button-flex-basis flex items-center justify-center">
        <div class="flex flex-col gap-y-3">
            <button mat-raised-button (click)="moveToRight()" [disabled]="!boxLeftData.length">
                <mat-icon>navigate_next</mat-icon>
                <mat-icon>navigate_next</mat-icon>
            </button>
            <button mat-raised-button (click)="moveToRight(boxLeft.selectedOptions.selected)" [disabled]="!boxLeft.selectedOptions.selected.length">
                <mat-icon>navigate_next</mat-icon>
            </button>
            <button mat-raised-button (click)="moveToLeft(boxRight.selectedOptions.selected)" [disabled]="!boxRight.selectedOptions.selected.length">
                <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-raised-button (click)="moveToLeft()" [disabled]="!boxRightData.length">
                <mat-icon>navigate_before</mat-icon>
                <mat-icon>navigate_before</mat-icon>
            </button>
        </div>
    </div>
    <mat-card class="box-flex-basis">
        <mat-card-content>
            <mat-selection-list #boxRight>
                @for (shoe of boxRightData; track shoe) {
                <mat-list-option togglePosition="before" [value]="shoe">{{shoe}}</mat-list-option>
                }
            </mat-selection-list>
        </mat-card-content>
    </mat-card>
</div>