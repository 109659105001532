import { Injectable } from "@angular/core";
import { BaseService, ServiceName } from "../../../../services/base-service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { APIUrlToUse } from "../../../../enums/api-url-to-use.enum";
import { Observable, map, shareReplay, switchMap, take } from "rxjs";
import { CancelQ2ORequest, GetNewPracticeResponse, GetPracticeResponse, GetQ2OHeaderResponse, GetTransationPracticeRequest, GetTransationPracticeResponse, SaveDraftRequest, SaveDraftResponse, SaveQ2ORequest, SaveQ2oResponse } from "../models/create-cost-structure.model";
import { APIResponse } from "../../../common/models/api-response.model";
import { selectRequestIdAndProcessId, selectRequestIdAndProcessIdAndComCode, selectRequesterComCode, selectTrnPractice } from "../../../../store/selectors/create-q2o-request.selector";
import { Store, select } from "@ngrx/store";
import { ActionLogResponse } from "../models/action-log.response";
import { ApproverListResponse } from "../models/approver-list.response";
import { GetCopyRequestInfo, SaveCopyRequestResponse } from "../models/copy-request.model";

@Injectable({
    providedIn: "root",
})
export class CreateCostStructureService extends BaseService {
    constructor(
        private http: HttpClient,
        private store: Store
    ) {
        super(APIUrlToUse.deployed, "http://localhost:3002", ServiceName.q2o);
    }

    private readonly url = {
        getPractice: "/q2o-request/common-dropdown/practice",
        getNewPractice: "/q2o-request/common-dropdown/new-practice",
        getTransationPractice: "/q2o-request/practice/get-transation-practice",
        saveDraft: "/q2o-request/save-draft",
        save: "/q2o-request/save",
        getQ2OHeader: "/q2o-request/",
        getActionLog: "/q2o-request/action-log",
        getApproverList: "/q2o-request/approver-list",
        calculate: "/calculate-q2o/calculate-zcs",
        cancelQ2O: "/q2o-request/cancel-q2o",
        copyRequestGetRequestInfo: "/q2o-request/copy-request/get-request-info",
        copyRequestSaveCopyRequest: "/q2o-request/copy-request/save-copy-request",
        withdraw: "/q2o-request/withdraw-revise-reject/withdraw",
    };

    private get getPracticeUrl(): string {
        return this.prepareUrl(this.url.getPractice);
    }
    private get getNewPracticeUrl(): string {
        return this.prepareUrl(this.url.getNewPractice);
    }
    private get getTransationPracticeUrl(): string {
        return this.prepareUrl(this.url.getTransationPractice);
    }
    private get saveDraftUrl(): string {
        return this.prepareUrl(this.url.saveDraft);
    }
    private get saveUrl(): string {
        return this.prepareUrl(this.url.save);
    }
    private get getQ2OHeaderUrl(): string {
        return this.prepareUrl(this.url.getQ2OHeader);
    }
    private get getActionLogUrl(): string {
        return this.prepareUrl(this.url.getActionLog);
    }
    private get getApproverListUrl(): string {
        return this.prepareUrl(this.url.getApproverList);
    }
    private get calculateUrl(): string {
        return this.prepareUrl(this.url.calculate);
    }
    private get cancelQ2OUrl(): string {
        return this.prepareUrl(this.url.cancelQ2O);
    }
    private get copyRequestGetRequestInfoUrl(): string {
        return this.prepareUrl(this.url.copyRequestGetRequestInfo);
    }
    private get copyRequestSaveCopyRequestUrl(): string {
        return this.prepareUrl(this.url.copyRequestSaveCopyRequest);
    }
    private get withdrawUrl(): string {
        return this.prepareUrl(this.url.withdraw);
    }

    getPractice(): Observable<APIResponse<GetPracticeResponse[]>> {
        return this.http.get<APIResponse<GetPracticeResponse[]>>(this.getPracticeUrl);
    }

    getNewPractice(): Observable<APIResponse<GetNewPracticeResponse[]>> {
        return this.http.get<APIResponse<GetNewPracticeResponse[]>>(this.getNewPracticeUrl);
    }
    
    getTransationPractice(): Observable<APIResponse<GetTransationPracticeResponse[]>> {
        return this.store.pipe(
            select(selectTrnPractice),
            take(1),
            switchMap((practice) => {
                const params: GetTransationPracticeRequest = {
                    trn_practice: practice,
                };
                let queryParams = this.convertObjectToHttpQueryParams(params);

                return this.http.get<APIResponse<GetTransationPracticeResponse[]>>(this.getTransationPracticeUrl, { params: queryParams });
            }),
        );
    }

    saveDraft(body: SaveDraftRequest): Observable<APIResponse<SaveDraftResponse>> {
        return this.http.post<APIResponse<SaveDraftResponse>>(this.saveDraftUrl, body);
    }

    save(body: SaveQ2ORequest): Observable<APIResponse<SaveQ2oResponse>> {
        return this.http.post<APIResponse<SaveQ2oResponse>>(this.saveUrl, body);
    }

    getQ2OHeader(requestId: number, processId: string): Observable<APIResponse<GetQ2OHeaderResponse>> {
        const params: HttpParams = new HttpParams()
            .set("request_id", requestId)
            .set("process_id", processId);
        return this.http.get<APIResponse<GetQ2OHeaderResponse>>(this.getQ2OHeaderUrl, { params: params });
    }

    getActionLog(requestId: number, processId: string): Observable<APIResponse<ActionLogResponse[]>> {
        const params: HttpParams = new HttpParams()
            .set("request_id", requestId)
            .set("process_id", processId);
        return this.http.get<APIResponse<ActionLogResponse[]>>(this.getActionLogUrl, { params: params });
    }

    getApproverList(requestId: number, processId: string): Observable<APIResponse<ApproverListResponse[]>> {
        const params: HttpParams = new HttpParams()
            .set("request_id", requestId)
            .set("process_id", processId);
        return this.http.get<APIResponse<ApproverListResponse[]>>(this.getApproverListUrl, { params: params });
    }

    calculate(): Observable<APIResponse<any>> {
        return this.store.pipe(
            select(selectRequestIdAndProcessId),
            take(1),
            switchMap((v) => {
                const body = {
                    request_id: v.requestId,
                    process_id: v.processId,
                    action: "RECAL"
                };
                return this.http.post<APIResponse<any>>(this.calculateUrl, body);
            }),
        );
    }

    practices$: Observable<GetPracticeResponse[]> = this.getPractice().pipe(
        map((i) => i.data),
        shareReplay(1),
    )
    newPractices$: Observable<GetNewPracticeResponse[]> = this.getNewPractice().pipe(
        map((i) => i.data),
        shareReplay(1),
    )

    cancelQ2O(body: CancelQ2ORequest): Observable<APIResponse<never>> {
        return this.http.post<APIResponse<never>>(this.cancelQ2OUrl, body);
    }

    copyRequestGetRequestInfo(): Observable<APIResponse<GetCopyRequestInfo>> {
        return this.store.pipe(
            select(selectRequestIdAndProcessId),
            take(1),
            switchMap((v) => {
                const params: HttpParams = new HttpParams()
                    .set("request_id", v.requestId)
                    .set("process_id", v.processId);
                return this.http.get<APIResponse<GetCopyRequestInfo>>(this.copyRequestGetRequestInfoUrl, { params: params });
            })
        );
    }

    copyRequestSaveCopyRequest(): Observable<APIResponse<SaveCopyRequestResponse>> {
        return this.store.pipe(
            select(selectRequestIdAndProcessId),
            take(1),
            switchMap((v) => {
                const body = {
                    request_id: v.requestId,
                    process_id: v.processId
                };
                return this.http.post<APIResponse<SaveCopyRequestResponse>>(this.copyRequestSaveCopyRequestUrl, body);
            }),
        );
    }

    withdraw(): Observable<APIResponse<never>> {
        return this.store.pipe(
            select(selectRequestIdAndProcessIdAndComCode),
            take(1),
            switchMap((v) => {
                const body = {
                    com_code: v.comCode,
                    request_id: v.requestId,
                };
                return this.http.post<APIResponse<never>>(this.withdrawUrl, body);
            }),
        );
    }
}
