import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, firstValueFrom, tap } from "rxjs";
import { environment } from "../../../../environments/environment.dev";
import { GetFwoResponseRequesterInformation } from "../../../api/fwd/fwd-request/create-fwo-request/models/get-fwo-response.model";
import { sleep } from "../../../class/utility/sleep";
import { PAGE_MODE } from "../../../enums/page-mode.enum";
import { RequesterInformationFormService, RequesterInformationDropdown } from "./requester-information-form.service";
import { Store, select } from "@ngrx/store";
import { RequesterInformation } from "../../../api/q2o/q2o-request/create-coststructure/models/create-fwo-request.model";
import { CreateFWORequestService } from "../../../api/fwd/fwd-request/create-fwo-request/services/create-fwo-request.service";
import { selectAuthorizationInfo, selectQ2ONewRequestFlag } from "../../../store/selectors/create-q2o-request.selector";
import { selectUserEmail, selectUserFirstName, selectUserLastName } from "../../../store/selectors/user.selector";
import { selectFWDAuthorizationInfo, selectFWDNewRequestFlag } from "../../../store/selectors/create-fwo-request.selector";
import { CostCenterDropdownResponse, RequesterDropdownResponse } from "../../../api/common/models/dropdown.model";
import { AuthorizationInfo } from "../../../api/q2o/request/models/create-cost-structure.model";

export interface RequesterInformationFormGroup {
    requesterEmail: FormControl<string | null>;
    creatorEmail: FormControl<string | null>;
    createdByEmail: FormControl<string | null>;
    createdByName: FormControl<string | null>;
    costCenter: FormControl<string | null>;
    salesGroup: FormControl<string | null>;
    department: FormControl<string | null>;
    division: FormControl<string | null>;
    companyCode: FormControl<string | null>;
    companyName: FormControl<string | null>;
    country: FormControl<string | null>;
    creatorCostCenter: FormControl<string | null>;
    creatorCompCode: FormControl<string | null>;
    creatorDivision: FormControl<string | null>;
}

@Component({
    selector: "app-requester-information",
    templateUrl: "./requester-information.component.html",
    styleUrl: "./requester-information.component.scss",
    providers: [RequesterInformationFormService],
})
export class RequesterInformationComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>();

    @Input() childFormGroup!: FormGroup<RequesterInformationFormGroup>;
    @Input() pageMode!: PAGE_MODE;
    @Input() isQ2O: boolean = false;
    @Output() onComponentReady = new EventEmitter<boolean>();

    form!: FormGroup<RequesterInformationFormGroup>;
    isProduction: boolean = environment.production;

    dropdown$ = this.requesterInformationFormService.dropdown$.pipe(
        tap((data) => {
            if (!this.isComponentReady) {
                this.isComponentReady = true;
                this.onComponentReady.emit(true);
            }
            if (!this.isDefaultRequesterSet) {
                this.isDefaultRequesterSet = true;
                this.setDefaultRequesterForNewRequestOnly(data);
            }
        })
    );

    // When component finished loading dropdown
    // then we mark it as ready and emit event
    isComponentReady: boolean = false;

    isDefaultRequesterSet: boolean = false;

    authorizationInfo$!: Observable<AuthorizationInfo>;

    disablesalegroup: boolean = true;

    constructor(
        private requesterInformationFormService: RequesterInformationFormService,
        private store: Store,
        private createFwoRequestService: CreateFWORequestService,
    ) { }

    ngOnInit(): void {
        this.form = this.childFormGroup;
        if (this.isQ2O) {
            this.authorizationInfo$ = this.store.pipe(select(selectAuthorizationInfo));
        }
        else {
            this.authorizationInfo$ = this.store.pipe(select(selectFWDAuthorizationInfo));
        }
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    get requesterNameForm() {
        return this.form.controls["requesterEmail"] as FormControl;
    }

    get creatorForm() {
        return this.form.controls["creatorEmail"] as FormControl;
    }

    requesterNameDisplayFunction() {
        return (option: RequesterDropdownResponse) => {
            if (option) {
                const companies = Array.from(new Set(option.cost_centers?.flatMap((i) => i.comp_code)));
                return `${option.name} ${option.lastname} (${option.email}) - ${companies.join("/")}`
            }
            return "";
        }
    }

    async setDefaultRequesterForNewRequestOnly(data: RequesterInformationDropdown) {
        try {
            const loggedInUserEmail = await firstValueFrom(this.store.select(selectUserEmail));
            const selector = this.isQ2O ? selectQ2ONewRequestFlag : selectFWDNewRequestFlag;

            const loggedInUserFirstName = await firstValueFrom(this.store.select(selectUserFirstName));
            const loggedInUserLastName = await firstValueFrom(this.store.select(selectUserLastName));
            console.log('loggedInUserLastName', loggedInUserLastName)
            const isNewRequest = await firstValueFrom(this.store.select(selector));
            if (!isNewRequest) {
                return;
            }
            // console.log('loggedInUserFirstName', loggedInUserFirstName)
            
            // Patch requester & creator email
            this.form.patchValue({
                requesterEmail: loggedInUserEmail,
                createdByEmail: loggedInUserEmail,
                createdByName: loggedInUserFirstName + " " + loggedInUserLastName,
                creatorEmail: loggedInUserEmail,
            });
            this.onSelectRequesterChange(loggedInUserEmail);
            this.onSelectCreator(loggedInUserEmail, data.creatorCostCenters);
            await sleep(100);
            
            // Patch requester cost center
            const requester = data.requesters.find((i)=> i.email === loggedInUserEmail);
            const costCenter: CostCenterDropdownResponse | null = requester?.cost_centers.length === 1 ? requester?.cost_centers[0]: null;
            if (!costCenter) {
                return;
            }
            this.form.patchValue({
                costCenter: costCenter.cost_center_name
            });
            this.onSelectCostCenter(costCenter.cost_center_name, null);
            await sleep(100);

            // Patch requester sales group and other info
            this.form.patchValue({
                salesGroup: "",
                department: costCenter.department,
                division: costCenter.division,
                companyCode: costCenter.comp_code,
                companyName: costCenter.comp_name_en,
                country: costCenter.country,
            });
            this.form.controls.salesGroup.disable();
           
            // Patch creator cost center, com code and division
            this.form.patchValue({
                creatorCompCode: costCenter.comp_code,
                creatorDivision: costCenter.division,
                creatorCostCenter: costCenter.cost_center_name,
            })
            this.onSelectCreatorCostCenter(costCenter.cost_center_name, [costCenter]);
            await sleep(100);
        }
        catch (error) {
            console.log(error);
        }
    }

    onSelectRequesterChange(requesterEmail: string) {
        this.requesterInformationFormService.onRequesterChange(requesterEmail);
        this.requesterInformationFormService.onCostCenterChange(null);
        this.form.patchValue({
            costCenter: null,
        });

        // why are you here?
        this.createFwoRequestService.onRequesterSelected(requesterEmail);
    }

    onSelectCreator(creatorEmail: string, creatorCostCenters: CostCenterDropdownResponse[]) {
        // Clear comp code and division form
        this.form.patchValue({
            creatorCostCenter: null,
            creatorCompCode: null,
            creatorDivision: null
        });

        this.requesterInformationFormService.onCreatorChange(creatorEmail);
    }

    onSelectCreatorCostCenter(costCenter: string, creatorCostCenters: CostCenterDropdownResponse[]) {
        const matched = creatorCostCenters.find((c) => c.cost_center_name === costCenter);
        if (matched) {
            this.form.patchValue({
                creatorDivision: matched.division,
                creatorCompCode: matched.comp_code
            });
        }
    }

    // dropdownData will be null in 2 cases
    // 1) patch old value
    // 2) set default value
    onSelectCostCenter(selectedCostCenter: string | null, dropdownData: RequesterInformationDropdown | null) {
        this.requesterInformationFormService.onCostCenterChange(selectedCostCenter);
        if (dropdownData !== null) {
            const costcenter = dropdownData.costCenters.find((i) => i.cost_center_name === selectedCostCenter);
            this.form.patchValue({
                department: costcenter?.department ?? null,
                division: costcenter?.division ?? null,
                companyCode: costcenter?.comp_code ?? null,
                companyName: costcenter?.comp_name_en ?? null,
                country: costcenter?.country ?? null,
            });
        }
    }

    async patchForm(data: GetFwoResponseRequesterInformation | RequesterInformation) {
        // Patch dependent dropdowns first
        this.form.patchValue({
            requesterEmail: data.requester_username,
        });
        this.onSelectRequesterChange(data.requester_username);
        await sleep(100);

        this.form.patchValue({
            costCenter: data.requester_cost_center,
        });
        this.onSelectCostCenter(data.requester_cost_center, null);
        await sleep(100);

        this.form.patchValue({
            creatorCompCode: data.creator_com_code,
            creatorDivision: data.creator_division,
            creatorCostCenter: data.creator_cost_center
        });
        await sleep(100);

        // Then patch independent ones
        this.form.patchValue({
            salesGroup: data.requester_sale_group,
            companyCode: data.requester_com_code,
            companyName: data.requester_com_name,
            country: data.requester_country,
            creatorEmail: data.creator_username,
            department: data.requester_dept,
            division: data.requester_division,
            createdByEmail: data.creator_username,
            createdByName : data.creator_fullname,
        });
        this.form.controls.salesGroup.disable();
        this.requesterInformationFormService.onCreatorChange(data.creator_username);

        const authInfo = await firstValueFrom(this.authorizationInfo$);
        if (!authInfo.can_edit) {
            this.form.disable();
        }
    }
}

export interface RequesterInformationFormValue {
    requesterEmail: string | null;
    creatorEmail: string | null;
    createdByEmail: string | null;
    createdByName : string | null;
    costCenter: string | null;
    salesGroup: string | null;
    department: string | null;
    division: string | null;
    companyCode: string | null;
    companyName: string | null;
    country: string | null;
}

