export enum ApproveRule {
    OneFromGroup = "OneFromGroup",
    Unanimously = "Unanimously", // all from group
    Majority = "Majority", // 50% or something
    Condition = "Condition",
}

export const AuthLevelMapping: AuthLevelMapping = {
    "1": "Assistant Manager",
    "2": "Section Manager",
    "3": "Department Manager",
    "4": "Division Manager",
    "5": "Business Director",
    "6": "Managing Director",
    "7": "Head of Business",
    "8": "Vice President",
    "9": "President SCG CBM",
    "10": "SCG Board of Director",
    null: "",
    "": "",
};

type AuthLevelMapping = {
    [key: string]: string;
};
